import * as types from '../../actions/constants';

const initialState = {
    token: 'HaGBYHqP4sbXght57wBfgNTkFf9JPDeH',
    
};

export default (state = {errormsg:null}, action) => {
     switch (action.type) {
        case types.CONTRIBUTORCREATE:            
            return { ...state, userData: action.user, cread: null };
        case types.USER_UPDATE_SUCCESS:            
            return { ...state, userData: action.user };
        case types.USER_ERROR_MSG:            
            return { ...state, errormsg: action.errormsg };
        default:
            return state;
    }
}