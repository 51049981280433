import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_STATS,SET_STATS_TOTAL,ISBN_COUNT,APPROVED_COUNT,UNAPPROVED_COUNT,BOOK_REF,DELETE_ERROR, START_NUMBER,SET_STATS ,BOOK_API_CALL,SEARCH_SUCCESS,REFERENCEERROR,SEARCH_ISBN_ERROR,SEARCH_ISBN_SUCCESS,REFERENCECREATE,SEARCH_UNAPPROVED_SUCCESS,SEARCH_ISAPPROVED_SUCCESS,REFERENCEEDIT,SHOW_LODER,SEARCH_NOT_FOUND,USER_NOT_FOUND,REFERENCEDELETE, GET_LIST_BOOK} from '../../actions/constants';
import { apiUrl } from '../../environment/index';
import axios from "axios";
import withAuth from '../withAuth';


const getReference = (pagenumber) => axios.get(`${apiUrl}admin/references?page=${pagenumber.page}`,{headers : withAuth()});
const getBookByIsbn = (pagenumber) => axios.get(`${apiUrl}admin/references?isbn_only&page=${pagenumber.page}`,{headers : withAuth(),'Content-Type': 'application/json'});
const getBooks = (value) => axios.get(`${apiUrl}admin/reference/${value}`,{headers : withAuth()});
const createRef = (refData) => axios.post(`${apiUrl}admin/add_reference`,refData,{headers : withAuth(),'Content-Type': 'application/json'});
const editRef = (refData) => axios.post(`${apiUrl}admin/update_reference/${refData.id}?`,refData,{headers : withAuth()});
const getBookByIsApproved = (pagenumber) => axios.get(`${apiUrl}admin/references?is_approved&page=${pagenumber.page}`,{headers : withAuth(),'Content-Type': 'application/json'});
const getBookByUnApproved = (pagenumber) => axios.get(`${apiUrl}admin/references?is_not_approved&page=${pagenumber.page}`,{headers : withAuth(),'Content-Type': 'application/json'});
const deleteRefUser = (id) => axios.delete(`${apiUrl}admin/reference/delete/${id}`,{headers : withAuth()});

export function* getBookSaga(pagenumber) {
  try {
    const response = yield call(getReference,pagenumber);
    if (response.data.status == 200) {
      yield put({type: SET_STATS_TOTAL, total: response.data.result.data.total});
      yield put({type: SET_STATS, stats: response.data.result.data.data});
      yield put({type: APPROVED_COUNT, approved_count: response.data.result.is_approved_count});
      yield put({type: UNAPPROVED_COUNT, unapproved_count: response.data.result.unapproved_count});
      yield put({type: ISBN_COUNT, isbn_count: response.data.result.isbn_only_count});
      yield put({ type: SHOW_LODER, isLoading: false });  
      } else {
        yield put({ type: SHOW_LODER, isLoading: false });
        yield put({ type: USER_NOT_FOUND, error:true  });
      }
  } catch (error) {
    yield put({ type: USER_NOT_FOUND, error  });

  }
}

export function* getBookByUnApprovedSaga(pagenumber) {
  try {
    yield put({ type: SHOW_LODER, isLoading: true});
    const response = yield call(getBookByUnApproved,pagenumber);
    if (response.data.status == 200) {
        // yield put({type: GET_LIST_BOOK, books:[]});
        yield put({type: SET_STATS, stats:  response.data.result.data.data});
        yield put({type: SET_STATS_TOTAL, total: response.data.result.data.total});
        yield put({type: UNAPPROVED_COUNT, unapproved_count: response.data.result.data.total});
        yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : false});
        yield put({ type: SHOW_LODER, isLoading: false});
      }
      else if (response.data.status == 400) {
        yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : true});
        yield put({ type: SHOW_LODER, isLoading: false});
      } 
      else {
          yield put({ type: SHOW_LODER, isLoading: false});
          yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : false});
          yield put({ type: SEARCH_ISBN_ERROR, response });
      }
      yield put({ type: SHOW_LODER, isLoading: false});
  } catch (error) {
      yield put({ type: SEARCH_ISBN_ERROR, error });
  }
}
  
export function* getBookByIsApprovedSaga(pagenumber) {
  try {
    yield put({ type: SHOW_LODER, isLoading: true});
    const response = yield call(getBookByIsApproved,pagenumber);
    if (response.data.status == 200) {
        // yield put({type: GET_LIST_BOOK, books:[]});
           yield put({type: SET_STATS, stats:  response.data.result.data.data});
           yield put({type: SET_STATS_TOTAL, total: response.data.result.data.total});
           yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : false});
           yield put({ type: SHOW_LODER, isLoading: false});
      } 
      else if (response.data.status == 400) {
        yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : true});
        yield put({ type: SHOW_LODER, isLoading: false});
      }
      else {
          yield put({ type: SHOW_LODER, isLoading: false});
          yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : false});
          yield put({ type: SEARCH_ISBN_ERROR, response });
      }
      yield put({ type: SHOW_LODER, isLoading: false});
  } catch (error) {
      yield put({ type: SEARCH_ISBN_ERROR, error });
  }
}

export function* getBookByIsbnSaga(pagenumber) {
  try {
    yield put({ type: SHOW_LODER, isLoading: true});
    const response = yield call(getBookByIsbn,pagenumber);
    if (response.data.status == 200) {
      //  yield put({type: GET_LIST_BOOK, books:[]});
        yield put({type: SET_STATS, stats: response.data.result.data.data});
        yield put({type: SET_STATS_TOTAL, total: response.data.result.data.total});
        yield put({type: ISBN_COUNT, isbn_count: response.data.result.data.total});
        yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : false});
        yield put({ type: SHOW_LODER, isLoading: false});
      }
      else if (response.data.status == 400) {
        yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : true});
        yield put({ type: SHOW_LODER, isLoading: false});
      } 
      else {
          yield put({ type: SHOW_LODER, isLoading: false});
          yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : false});
          yield put({ type: SEARCH_ISBN_ERROR, response });
      }
      yield put({ type: SHOW_LODER, isLoading: false});
  } catch (error) {
      yield put({ type: SEARCH_ISBN_ERROR, error });
  }
}

export function* searchBooks (searchValue) {
  try {
    yield put({ type: SHOW_LODER, isLoading: true});
    if(searchValue.value.trim().length == 0){
      yield put({ type: SHOW_LODER, isLoading: false});
    }
    else{
      const response = yield call(getBooks,searchValue.value);
      if (response.data.status == 200) { 
          yield put({type: GET_LIST_BOOK, books:[]});
          yield put({type: SET_STATS, stats:  response.data.result.data.data});
          yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : false });
          yield put({ type: SHOW_LODER, isLoading: false});
          // yield put({type: SEARCH_SUCCESS, stats: response.data.result});
        }
        else if (response.data.status == 400) {
          yield put({type: GET_LIST_BOOK, books:[]});
          yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : true});
          yield put({ type: SHOW_LODER, isLoading: false});
        }
        else{
           yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : false });
           yield put({ type: SHOW_LODER, isLoading: false});
        }
      }
      yield put({ type: SHOW_LODER, isLoading: false});   
  } 
  catch (error) 
  {
    yield put({ type: SEARCH_NOT_FOUND, searcherrormsg : false });
  }
}

export function* createReferenceSaga(refData){
  try {
    const Data =  refData.refData;
    yield put({ type: SHOW_LODER, isLoading: true});
    const response = yield call(createRef, Data);    
    let unapprove_flag = localStorage.getItem("unapprove_flag"); 
    let approve_flag = localStorage.getItem("approve_flag"); 
    let isbn_flag = localStorage.getItem("isbn_flag");       
    if(response.data.status == 200)
    {
      const pagenumber = {
        page:1
      }
      if(unapprove_flag == "true"){
        const getunapprovedata = yield call(getBookByUnApproved,pagenumber);
        if(getunapprovedata.data.status == 200){
          yield put({type: GET_LIST_BOOK, books:[]});
          yield put({type: SET_STATS, stats:  getunapprovedata.data.result.data.data});
          yield put({ type: START_NUMBER, page: pagenumber.page });
          yield put({type: APPROVED_COUNT, approved_count: getunapprovedata.data.result.is_approved_count});
          yield put({type: UNAPPROVED_COUNT, unapproved_count: getunapprovedata.data.result.unapproved_count});
          yield put({type: ISBN_COUNT, isbn_count: getunapprovedata.data.result.isbn_only_count});
        }
      }
      else if(approve_flag == "true"){
        const getapprovedata = yield call(getBookByIsApproved,pagenumber);
        if(getapprovedata.data.status == 200){
          yield put({type: GET_LIST_BOOK, books:[]});
          yield put({type: SET_STATS, stats:  getapprovedata.data.result.data.data});
          yield put({ type: START_NUMBER, page: pagenumber.page });
          yield put({type: APPROVED_COUNT, approved_count: getapprovedata.data.result.is_approved_count});
          yield put({type: UNAPPROVED_COUNT, unapproved_count: getapprovedata.data.result.unapproved_count});
          yield put({type: ISBN_COUNT, isbn_count: getapprovedata.data.result.isbn_only_count});
        }
      }
      else if(isbn_flag == "true"){
        const getisbnata = yield call(getBookByIsbn,pagenumber);
        if(getisbnata.data.status == 200){
          yield put({type: GET_LIST_BOOK, books:[]});
          yield put({type: SET_STATS, stats:  getisbnata.data.result.data.data});
          yield put({ type: START_NUMBER, page: pagenumber.page });
          yield put({type: APPROVED_COUNT, approved_count: getisbnata.data.result.is_approved_count});
          yield put({type: UNAPPROVED_COUNT, unapproved_count: getisbnata.data.result.unapproved_count});
          yield put({type: ISBN_COUNT, isbn_count: getisbnata.data.result.isbn_only_count});
        }
      }
      else{
         const getbookdata = yield call(getReference,pagenumber);
        if(getbookdata.data.status == 200){
          yield put({type: GET_LIST_BOOK, books:[]});
          yield put({type: SET_STATS, stats:  getbookdata.data.result.data.data});
          yield put({ type: START_NUMBER, page: pagenumber.page });
          yield put({type: APPROVED_COUNT, approved_count: getbookdata.data.result.is_approved_count});
          yield put({type: UNAPPROVED_COUNT, unapproved_count: getbookdata.data.result.unapproved_count});
          yield put({type: ISBN_COUNT, isbn_count: getbookdata.data.result.isbn_only_count});
        }
      }
        //yield put({ type: SHOW_LODER, isLoading: false});
      }
      else
      {
        yield put({ type: SHOW_LODER, isLoading: false});
        yield put({ type: REFERENCEERROR, response });
      }
      yield put({ type: SHOW_LODER, isLoading: false});
  
    }
    catch (error) {
      yield put({ type: REFERENCEERROR, error });
    }

}

export function* editReferenceSaga({refData}) 
{
  try {
    yield put({ type: SHOW_LODER, isLoading: true});
    const response = yield call(editRef, refData);
    let unapprove_flag = localStorage.getItem("unapprove_flag"); 
    let approve_flag = localStorage.getItem("approve_flag"); 
    let isbn_flag = localStorage.getItem("isbn_flag");  
        if(response.data.status == 200)
        {
          const pagenumber = {
            page:1
          }
          if(unapprove_flag == "true"){
            const getunapprovedata = yield call(getBookByUnApproved,pagenumber);
            if(getunapprovedata.data.status == 200){
              yield put({type: GET_LIST_BOOK, books:[]});
              yield put({type: SET_STATS, stats:  getunapprovedata.data.result.data.data});
              yield put({ type: START_NUMBER, page: pagenumber.page });
              yield put({type: APPROVED_COUNT, approved_count: getunapprovedata.data.result.is_approved_count});
              yield put({type: UNAPPROVED_COUNT, unapproved_count: getunapprovedata.data.result.unapproved_count});
              yield put({type: ISBN_COUNT, isbn_count: getunapprovedata.data.result.isbn_only_count});
            }
          }
          else if(approve_flag == "true"){
            const getapprovedata = yield call(getBookByIsApproved,pagenumber);
            if(getapprovedata.data.status == 200){
              yield put({type: GET_LIST_BOOK, books:[]});
              yield put({type: SET_STATS, stats:  getapprovedata.data.result.data.data});
              yield put({ type: START_NUMBER, page: pagenumber.page });
              yield put({type: APPROVED_COUNT, approved_count: getapprovedata.data.result.is_approved_count});
              yield put({type: UNAPPROVED_COUNT, unapproved_count: getapprovedata.data.result.unapproved_count});
              yield put({type: ISBN_COUNT, isbn_count: getapprovedata.data.result.isbn_only_count});
            }
          }
          else if(isbn_flag == "true"){
            const getisbnata = yield call(getBookByIsbn,pagenumber);
            if(getisbnata.data.status == 200){
              yield put({type: GET_LIST_BOOK, books:[]});
              yield put({type: SET_STATS, stats:  getisbnata.data.result.data.data});
              yield put({ type: START_NUMBER, page: pagenumber.page });
              yield put({type: APPROVED_COUNT, approved_count: getisbnata.data.result.is_approved_count});
              yield put({type: UNAPPROVED_COUNT, unapproved_count: getisbnata.data.result.unapproved_count});
              yield put({type: ISBN_COUNT, isbn_count: getisbnata.data.result.isbn_only_count});
            }
          }
          else{
            const getbookdata = yield call(getReference,pagenumber);
            if(getbookdata.data.status == 200){
              yield put({type: GET_LIST_BOOK, books:[]});
              yield put({type: SET_STATS, stats:  getbookdata.data.result.data.data});
              yield put({ type: START_NUMBER, page: pagenumber.page });
              yield put({type: APPROVED_COUNT, approved_count: getbookdata.data.result.is_approved_count});
              yield put({type: UNAPPROVED_COUNT, unapproved_count: getbookdata.data.result.unapproved_count});
              yield put({type: ISBN_COUNT, isbn_count: getbookdata.data.result.isbn_only_count});
            }
          }
        }
        else
        {
          yield put({ type: SHOW_LODER, isLoading: false});
          yield put({ type: REFERENCEERROR, response });
        }
        yield put({ type: SHOW_LODER, isLoading: false});
        
    } catch (error) {
      yield put({ type: REFERENCEERROR, error });
    }
}

export function* deleteRefSaga(data) {
  try 
  {
    yield put({ type: SHOW_LODER, isLoading: true});
    var id = data.data.ref_id;
      const response = yield call(deleteRefUser,id);
      if(data.data.isbnchecked == true && response.status == 200)
      {
        const pagenumber = {
          page:1
        }
        const getbookdata = yield call(getBookByIsbn,pagenumber);
        if(getbookdata.data.status == 200){
          yield put({type: GET_LIST_BOOK, books:[]});
          yield put({type: SET_STATS, stats: getbookdata.data.result.data.data});
          yield put({ type: START_NUMBER, page: pagenumber.page });
          yield put({type: APPROVED_COUNT, approved_count: getbookdata.data.result.is_approved_count});
          yield put({type: UNAPPROVED_COUNT, unapproved_count: getbookdata.data.result.unapproved_count});
          yield put({type: ISBN_COUNT, isbn_count: getbookdata.data.result.isbn_only_count});
        }
        yield put({ type: SHOW_LODER, isLoading: false}); 
      } 
      else if(data.data.disapprovedchecked == true && response.status == 200)
      {
        const pagenumber = {
          page:1
        }
        const getbookdata = yield call(getBookByUnApproved,pagenumber);
        if(getbookdata.data.status == 200){
          yield put({type: GET_LIST_BOOK, books:[]});
          yield put({type: SET_STATS, stats: getbookdata.data.result.data.data});
          yield put({ type: START_NUMBER, page: pagenumber.page });
          yield put({type: APPROVED_COUNT, approved_count: getbookdata.data.result.is_approved_count});
          yield put({type: UNAPPROVED_COUNT, unapproved_count: getbookdata.data.result.unapproved_count});
          yield put({type: ISBN_COUNT, isbn_count: getbookdata.data.result.isbn_only_count});
        }
        yield put({ type: SHOW_LODER, isLoading: false});
      }
      else if(data.data.is_approved_checked == true && response.status == 200)
      {
        const pagenumber = {
          page:1
        }
        const getbookdata = yield call(getBookByIsApproved,pagenumber);
        if(getbookdata.data.status == 200){
          yield put({type: GET_LIST_BOOK, books:[]});
          yield put({type: SET_STATS, stats: getbookdata.data.result.data.data});
          yield put({ type: START_NUMBER, page: pagenumber.page });
          yield put({type: APPROVED_COUNT, approved_count: getbookdata.data.result.is_approved_count});
          yield put({type: UNAPPROVED_COUNT, unapproved_count: getbookdata.data.result.unapproved_count});
          yield put({type: ISBN_COUNT, isbn_count: getbookdata.data.result.isbn_only_count});
        }
        yield put({ type: SHOW_LODER, isLoading: false});
      }
      else{
        if(response.status == 200)
        {
          const pagenumber = {
            page:1
          }
          const getbookdata = yield call(getReference,pagenumber);
          if(getbookdata.data.status == 200){
            yield put({type: GET_LIST_BOOK, books:[]});
            yield put({type: SET_STATS, stats: getbookdata.data.result.data.data});
            yield put({ type: START_NUMBER, page: pagenumber.page });
            yield put({type: APPROVED_COUNT, approved_count: getbookdata.data.result.is_approved_count});
            yield put({type: UNAPPROVED_COUNT, unapproved_count: getbookdata.data.result.unapproved_count});
            yield put({type: ISBN_COUNT, isbn_count: getbookdata.data.result.isbn_only_count});
          }
          yield put({ type: SHOW_LODER, isLoading: false}); 
        } 
      }
  }
  catch (error) {
    yield put({ type: DELETE_ERROR, error });
  }
}




function* watchUserAuthentication() {
  yield all([takeLatest(BOOK_API_CALL, getBookSaga)]);
  yield all([takeLatest(SEARCH_SUCCESS, searchBooks)]);
  yield all([takeLatest(SEARCH_ISBN_SUCCESS, getBookByIsbnSaga)]);
  yield all([takeLatest(REFERENCECREATE, createReferenceSaga)]);
  yield all([takeLatest(REFERENCEEDIT, editReferenceSaga)]);
  yield all([takeLatest(SEARCH_ISAPPROVED_SUCCESS, getBookByIsApprovedSaga)]);
  yield all([takeLatest(SEARCH_UNAPPROVED_SUCCESS, getBookByUnApprovedSaga)]);
  yield all([takeLatest(REFERENCEDELETE, deleteRefSaga)]);
}

export default watchUserAuthentication;