import { REFERENCECREATE,SHOW_LODER} from '../../actions/constants';

export default (state = {}, action) => {
    switch (action.type) {
        case REFERENCECREATE:
            return {...state,data:action.refData};
        case SHOW_LODER:
            return { ...state, isLoading: action.isLoading };
        default:
            return state;
    }
}