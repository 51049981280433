import React, { useEffect, Component, useState } from "react";
import { useDispatch, useSelector, connect } from 'react-redux';
import { getuserlist, ContributorUserEditAction } from "../../actions/index";
import MUIDataTable from "mui-datatables";
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogTitle, FormControlLabel } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ContributorUserAction, ContributorDeleteAction, ContributorMultipleDeleteAction, setLoading } from "../../actions/index";
import { TextField, Button, Switch } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@material-ui/icons/Edit';
import FullPageLoader from '../FullPageLoader/loader';
import { USER_ERROR_MSG, SHOW_LODER, PAGE_NUMBER,DELETE_SUCCESS,USER_EMAIL_ERROR_MSG, GET_LIST_BOOK, SET_STATS, START_NUMBER } from '../../actions/constants';
import SearchBar from "material-ui-search-bar";
import {Redirect } from "react-router-dom";

export class Users extends Component {
  render() {
    const data = { ...this.props, ...this.state };
    return (<User {...data} />);
  }
}

const User = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false); // for switch button (is_approved)
  const [editopen, editsetOpen] = React.useState(false); // edit modal 
  const [multipledeleteopen, setMulDeleteOpen] = React.useState(false); // multiple delete modal
  const [user_delete_ids, setUserDeleteIds] = React.useState(''); // fetch multiple delete ids
  const [deleteopen, deletesetOpen] = React.useState(false); // delete modal
  const users = useSelector(state => state.Users.users); // fetch user data

  const totalrecord = users && users.total;
  const theme = useTheme(); // for theme
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [formData, updateFormData] = useState([]); // For add/update data 
  const [emailError, setEmailError] = useState('') // email validation 
  const [passwordError, setPasswordError] = useState('') // password validation
  const [nameError, setNameError] = useState('') // Name validation
  const [apionce, setOnce] = useState(""); // Prevent to call multiple time get ref api

  // START ::  fetch data while edit into popup 
  const [user_id, setuserid] = useState("");
  const [contributor_name, setName] = useState("");
  const [contributor_email, setEmail] = useState("");
  const [contributor_approved, setApproved] = useState(false);
  const [add_contributor, setContributor] = useState("");
  const [contributor_password, setPassword] = useState("");
  const [usersdata, setUserdata] = useState([]); // For update book data for pagination

  const handleEditClick = (tableMeta, value) => {
    editsetOpen(true);
    const tableData = tableMeta.rowData;
    setuserid(tableData[0]);
    {
      users && users.data.map((userdata) => {
        if (userdata.id == tableData[0]) {
          setName(userdata.name);
          setEmail(userdata.email);
          setPassword(userdata.password);
          setApproved(userdata.allow_to_approve_reference == true ? true : false);
          setContributor(userdata.allow_to_add_contributor == true ? true : false);
        }
      })
    }
  }
  // END ::  fetch data while edit into popup 

  // Start :: Multiple delete modal event 
  const handleMulDeleteSubmit = (e) => {
    var ids = user_delete_ids;
    if (ids.length > 0) {
      dispatch(ContributorMultipleDeleteAction(ids))
      toast.success("User data delete successfully", {
        position: "top-right",
        autoClose: 1000
      });
    }
    setMulDeleteOpen(false);
    setsearch_data('')
  }
  const handleMulDeleteClose = () => {
    setMulDeleteOpen(false);
    setRowsSelected([]);
  };
  // End :: Multiple delete modal event 

  // START :: Delete Modal 
  const handleDeleteClick = (tableMeta) => {
    deletesetOpen(true);
    const tableData = tableMeta.rowData;
    setuserid(tableData[0]);
  }
  // END :: Delete Modal

  // START :: Delete Submit Event
  const handleDeleteSubmit = (e) => {
    var ids = user_id;
    dispatch(ContributorDeleteAction(ids.toString()))
    toast.success("Data deleted successfully ", {
      position: "top-right",
      autoClose: 1000
    });
    deletesetOpen(false);
    editsetOpen(false);
    setsearch_data('');
  }
  // END :: Delete Submit Event

  // START :: Edit Submit Event
  const handleEditSubmit = (e) => {
    var id = user_id;
    var name = contributor_name;
    var email = contributor_email;
    var allow_to_approve_reference = contributor_approved;
    var allow_to_add_contributor = add_contributor;
    let isValid = true;
    if (!name) {
      isValid = false;
      setNameError("Please enter your username.");
    }
    if (!email) {
      isValid = false;
      setEmailError("Please enter your email address.");
    }
    if (typeof email !== "undefined") {
      var pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
      if (!pattern.test(email)) {
        isValid = false;
        setEmailError("Please enter valid email address.");
      }
    }
    let isErrValid = true;
    if (name) {
      setNameError('');
      isErrValid = false;
    }
    if (email) {
      var pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
      if (pattern.test(email)) {
      setEmailError('');
      isErrValid = false;
      }
    }
    if ((name && name != "") && (email && email != "")) {
      var userData = { id: id, name: name, email: email, allow_to_approve_reference: allow_to_approve_reference, allow_to_add_contributor: allow_to_add_contributor }
      dispatch(ContributorUserEditAction(userData))
      toast.success("Data update successfully", {
        position: "top-right",
        autoClose: 1000
      });
      editsetOpen(false);
    }
    else {
      toast.error("Email & Name are required", {
        position: "top-right",
        autoClose: 1000
      });
    }
  }
  // END :: Edit Submit Event

  // START :: Datatable Column Define
  const columns = [{ name: "id", label: "ID" }, { name: "name", label: "Name" }, { name: "email", label: "Email" },
  {
    name: "allow_to_approve_reference",
    label: "Allowed to Approve Reference",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <div>
          {value ? "Yes" : "No"}
        </div>;
      }
    },
  },
  {
    name: "Actions",
    options: {
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            <EditIcon className="m-0 no-margin" onClick={() => handleEditClick(tableMeta, value)} />
            <DeleteIcon onClick={() => handleDeleteClick(tableMeta)} />
          </>
        );
      }
    }
  }
  ];
  // END :: Datatable Column Define

  // START :: Add Modal open & close event
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNameError('');
    setEmailError('');
    setPasswordError('');
  };
  // END :: Add Modal open & close event

  // START :: Delete Modal close event
  const handleDeleteClose = () => {
    deletesetOpen(false);
    setRowsSelected([]);
  };
  // END :: Delete Modal close event

  // START :: Edit Modal close event
  const handleEditClose = () => {
    editsetOpen(false);
    setRowsSelected([]);
  };
  // END :: Edit Modal close event
  useEffect(() => {
    if (props.users) {
      setUserdata(props.users)
    }
    props.dispatch({ type: DELETE_SUCCESS, success: null });
    props.dispatch({ type: USER_ERROR_MSG, errormsg: null });
  }, [props.users])

  const [rowsSelected, setRowsSelected] = useState([]); // For row selected details
  const [rowsPerPage, setRowsPerPage] = useState(15); // For row selected details
  const onRowsSelect = (_, allRows) => {
    setRowsSelected(allRows.map(row => row.index));
  };
  
  // Start :: Add button custom on datatable
  const pageno = props.pageno;
  const [search_value, setsearch_data] = useState(""); // For Search Data
  const handleSearch = (e) => {
    setsearch_data(e)
  }
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (search_value) {
      props.dispatch({ type: SHOW_LODER, isLoading: true })
      var pageno = 1;
      dispatch(getuserlist(pageno, search_value));
      props.dispatch({ type: PAGE_NUMBER, pageno: pageno });
    }
    else {
      props.dispatch({ type: SHOW_LODER, isLoading: true })
      dispatch(getuserlist(pageno, ""));
      props.dispatch({ type: PAGE_NUMBER, pageno: pageno });
    }
  }
  const handleCloseSearch = () => {
    props.dispatch({ type: SHOW_LODER, isLoading: true })
    dispatch(getuserlist(pageno, ""));
    props.dispatch({ type: PAGE_NUMBER, pageno: pageno });
  }
 const userslength = users && users.data && users.data.length;
  const options = {
    filterType: 'checkbox',
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: 'multiple',
    count: totalrecord,
    search: false,
    page: pageno-1,
    selectableRowsHeader: userslength > 0,
    pagination: "true",
    rowsPerPageOptions: false,
    rowsPerPage: rowsPerPage,
    serverSide: true,
    responsive: "scroll",
    sort: true,
    textLabels: {
      body: {
        noMatch: props.isLoading ?
          'Data Loading.....' :
          'Sorry no matching record found',
      }
    },
    onRowsSelect: onRowsSelect,
    rowsSelected: rowsSelected,
    onRowsDelete: (rowsDeleted, data) => {
      setMulDeleteOpen(true);
      let ids = [];
      if (users && users.data && users.data) {
        if (rowsDeleted && rowsDeleted && rowsDeleted.data.length) {
          rowsDeleted.data.forEach(r => {
            var multipleids = users.data[r.index].id;
            ids = ids.concat(multipleids)
            setUserDeleteIds(ids.toString())
          });
        }
      }
      //  return false;
    },
    onChangePage: (page) => {
      if (users !== undefined) {
        props.dispatch({ type: SHOW_LODER, isLoading: true });
      }
      dispatch(getuserlist(page + 1, ""));
      props.dispatch({ type: PAGE_NUMBER, pageno: page + 1 });
      setRowsSelected([]);
    },
    customToolbar: () => (
      <>
        <div className="d-flex align-items-center justify-content-end search-user-tbl">
          <form onSubmit={(e) => handleSearchSubmit(e)}>
            <div className="user_search_div">
              <SearchBar className="header-search-bar" style={{ width:'100%', padding: '0px', display: 'flex', height: '37px', position: 'relative' }}
                className="user_search_div"
                onChange={(e) => handleSearch(e)}
                value={search_value}
                placeholder="Search User"
                onCancelSearch={(e) => handleCloseSearch(e)}
                name="user-search" id="user_search"
              />
            </div>
          </form>
          <AddIcon onClick={handleClickOpen} />
        </div>
      </>
    )
  };
  // End :: Add button custom on datatable

  // Input Handle CHange Event
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  //Start :: Switch button event
  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleApproveChange = (event) => {
    setApproved(event.target.checked);
  }

  const handleContributorChange = (event) => {
    setContributor(event.target.checked);
  }
  // End :: Switch button event
  // Add Event
  const handleSubmit = (e) => {
    e.preventDefault();
    var name = formData.name;
    var email = formData.email;
    var password = formData.password;
    var allow_to_approve_reference = checked ? true : false;
    var allow_to_add_contributor = checked ? true : false;
    let isValid = true;
    if (!name) {
      isValid = false;
      setNameError("Please enter your username.");
    }
    if (!email) {
      isValid = false;
      setEmailError("Please enter your email address.");
    }
    if (typeof email !== "undefined") {
      var pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
      if (!pattern.test(email)) {
        isValid = false;
        setEmailError("Please enter valid email address.");
      }
    }

    if (!password) {
      isValid = false;
      setPasswordError("Please enter your password.");
    }
    if (typeof password !== "undefined") {
      if (password.length < 6) {
        isValid = false;
        setPasswordError("Please add at least 6 characters.");
      }
    }
    let isErrValid = true;
    var pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
    if (name) {
      setNameError('');
      isErrValid = false;
    }
    if (email) {
      var pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
      if (pattern.test(email)) {
        setEmailError('');
        isErrValid = false;
      }
    }
    if (password && password != "" && password.length >= 6) {
      setPasswordError('');
      isErrValid = false;
    }
    if ((name && name != "") && (email && email != "" && pattern.test(email))) {
      if (password && password != "" && password.length >= 6) {
        setPasswordError('');
        var data = { name: name, email: email, password: password, allow_to_approve_reference: allow_to_approve_reference, allow_to_add_contributor: allow_to_add_contributor }
        dispatch(ContributorUserAction(data))
      }
      else {
        //   toast.error("Password must be at least 6 characters long", {
        //     position: "top-right",
        //     autoClose: 1000
        // });
      }
    }
    else {
      // toast.error("Please fill require fields", {
      //       position: "top-right",
      //       autoClose: 1000
      //   });
    }
  }

  // Validation if email already exists
  useEffect(() => {
    if (props.errormsg === true) {
      toast.error("Email already exists.", {
        position: "top-right",
        autoClose: 1000
      });
      props.dispatch({ type: USER_ERROR_MSG, errormsg: null });
    }
    if (props.errormsg === false) {
      toast.success("Data added successfully", {
        position: "top-right",
        autoClose: 1000
      });
      setOpen(false);
      props.dispatch({ type: USER_ERROR_MSG, errormsg: null });
    }
    props.dispatch({ type: DELETE_SUCCESS, success: null });
    props.dispatch({ type: USER_ERROR_MSG, errormsg: null });

  }, [props.errormsg])


  // Validation if email already exists

  useEffect(() => {
    if(props.success == true){
      setRowsSelected([]);
      return true;
    }
    props.dispatch({ type: DELETE_SUCCESS, success: null });
  },[props.success])

  // Get user list
  useEffect(() => {
    if (props.success == true) {
      setRowsSelected([]);
      return true;
    }
    props.dispatch({ type: DELETE_SUCCESS, success: null });
    props.dispatch({ type: USER_ERROR_MSG, errormsg: null });
    props.dispatch({ type: START_NUMBER, page: 1 });
    props.dispatch({ type: GET_LIST_BOOK, books: [] });
    props.dispatch({ type: SET_STATS, stats: [] });
    if (users && users.data != null && users.data != undefined) {
      dispatch(setLoading(false))
    }
    else {
      dispatch(setLoading(true))
    }
    dispatch(getuserlist(props.pageno, ""));
    props.dispatch({ type: PAGE_NUMBER, pageno: props.pageno });
  }, [apionce])

  // prevent autofeel value from textfield
  const hideAutoFillColorStyle = {
    WebkitBoxShadow: '0 0 0 1000px white inset'
  };
  const hintStyle = { zIndex: '1' };

  return (
    <div className="usersli-main">
      {props.isLoading ? <FullPageLoader /> : ""}
      <MUIDataTable
        title={"Users List"}
        data={users ? users.data : []}
        columns={columns}
        options={options}
        className="usersli-table"
      />
      <Dialog
        fullScreen={fullScreen}
        className="contributor-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title title_tag">{"Contributor"}<CloseIcon className="c-pointer" style={{ float: "right" }} onClick={handleClose} ></CloseIcon></DialogTitle>
        <hr className="mb-0 mt-0" />
        <DialogContent>
          <DialogContentText id="responsive-dialog-title">
            <form autocomplete="off">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <TextField
                    autoComplete='off'
                    id="standard-full-width"
                    label="Name"
                    name="name"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleChange(e)}
                  />
                  <div className="text-danger">{nameError}</div>
                </div>
                <div>
                  <TextField
                    id="standard-full-width"
                    label="Email"
                    name="email"
                    inputStyle={hideAutoFillColorStyle}
                    hintStyle={hintStyle}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleChange(e)}
                  />
                  <div className="text-danger">{emailError}</div>
                </div>
                <div>
                  <TextField
                    id="standard-full-width"
                    label="Password"
                    type="password"
                    name="password"
                    inputProps={{
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleChange(e)}
                  />
                  <div className="text-danger">{passwordError}</div>
                </div>
                <div>
                  <FormControlLabel className="approve_label"
                    control={
                      <Switch
                        onChange={handleSwitchChange}
                        value="allow_to_approve_reference"
                      />
                    }
                    label="Allow To Approve Reference"
                    classes="f-w-500"
                  />
                </div>
                <div>
                  <FormControlLabel className="contributor_label"
                    control={
                      <Switch
                        onChange={handleSwitchChange}
                        value="allow_to_add_contributor"
                      />
                    }
                    label="Allow To Add Contributor"
                    classes="f-w-500"
                  />
                </div>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <hr className="mb-2 mt-0" />
        <DialogActions className="mb-2">
          <Button variant="contained" autoFocus className="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" autoFocus className="primary" onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />

      {/* =====================================================Edit Modal ============================================================== */}
      <Dialog
        fullScreen={fullScreen}
        open={editopen}
        onClose={handleEditClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title title_tag">{"Edit Contributor"}<CloseIcon style={{ float: "right" }} onClick={handleEditClose} ></CloseIcon></DialogTitle>
        <DialogContent>
          <DialogContentText id="responsive-dialog-title">
            <form >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <TextField
                    id="standard-full-width"
                    label="Name"
                    name="name"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => setName(e.target.value)}
                    value={contributor_name}
                  />
                </div>
                <div className="text-danger">{nameError}</div>
                <div>
                  <TextField
                    id="standard-full-width"
                    label="Email"
                    name="email"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => setEmail(e.target.value)}
                    value={contributor_email}
                  />
                </div>
                <div className="text-danger">{emailError}</div>
                <div>
                  <FormControlLabel className="approve_label"
                    control={
                      <Switch
                        onChange={handleApproveChange}
                        checked={contributor_approved}
                      />
                    }
                    label="Allow To Approve Reference"
                  />
                </div>
                <div>
                  <FormControlLabel className="contributor_label"
                    control={
                      <Switch
                        onChange={handleContributorChange}
                        checked={add_contributor}
                      />
                    }
                    label="Allow To Add Contributor"
                  />
                </div>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <hr className="mb-2 mt-0" />
        <DialogActions className="mb-2">
          <Button variant="contained" autoFocus color="primary" className="book_btn" onClick={handleEditClose}>Cancel</Button>
          <Button variant="contained" autoFocus color="primary" className="book_btn" onClick={handleEditSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      {/* ==============================================Delete Modal ============================================================== */}
      <Dialog
        open={deleteopen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="responsive-dialog-title title_tag">{"Delete User"}<CloseIcon style={{ float: "right" }} onClick={handleDeleteClose} ></CloseIcon></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete selected user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus color="primary" className="book_btn" onClick={handleDeleteClose}>Cancel</Button>
          <Button variant="contained" autoFocus color="primary" className="book_btn" onClick={handleDeleteSubmit}>Delete</Button>
        </DialogActions>
      </Dialog>

      {/* ============================================== Multiple Delete Modal ============================================================== */}
      <Dialog
        open={multipledeleteopen}
        onClose={handleMulDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="responsive-dialog-title title_tag">{"Delete User"}<CloseIcon style={{ float: "right" }} onClick={handleMulDeleteClose} ></CloseIcon></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete selected user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus color="primary" className="book_btn" onClick={handleMulDeleteClose}>Cancel</Button>
          <Button variant="contained" autoFocus color="primary" className="book_btn" onClick={handleMulDeleteSubmit}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  const { Users, contributor } = state;
  return { ...Users, ...contributor };
};
export default connect(mapStateToProps, null)(Users);