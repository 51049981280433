import './App.css';
import login from './components/LoginForm/login';
import React, { Suspense } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Link, BrowserRouter as Router, Route,HashRouter, Switch, Redirect,hashHistory } from "react-router-dom";
import { protectedroutes } from "./routes";
import {  makeStyles } from '@material-ui/core/styles';
import Sidebar from './components/Sidebar/Sidebar';
import history from './history';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexDirection: 'row' },
  details: { display: 'flex', flexDirection: 'column', },
  content: { flex: '1 0 auto', },
  cover: { width: 151, },
  controls: { display: 'flex', alignItems: 'center', paddingLeft: theme.spacing(1), paddingBottom: theme.spacing(1), },
  playIcon: { height: 38, width: 38, },
  rcomponent: { width: 'calc(100% - 240px)' }
}));


function App() {
  const classes = useStyles();
  return (
    <div className="App" >
      {/* <Col> <Header></Header> </Col> */}
      <div className={classes.root}>
        <HashRouter  history={history} forceRefresh={true}>
          <Sidebar></Sidebar>
          <div className={classes.rcomponent}>
            <Suspense fallback={<div>Loading..</div>}>
              <Switch>
                {protectedroutes.map((route, index) => (
                  <Route key={index} path={route.path} exact={route.exact} component={route.component} ></Route>
                ))}
                <Route key={protectedroutes.length} path='/login' exact={true} component={login} ></Route>
                <Route path="/*" ><Redirect to='/login' /></Route>
              </Switch>
            </Suspense>
          </div>
        </HashRouter>
      </div>
    </div>
  );
}

export default App;
