import * as TYPE from '../actions/constants';

export const loginUserAction = (user) => {
  return {
    type: TYPE.USER_LOGIN,
    user
  }
};
export const logoutAction = () => {
  return {
    type: TYPE.USER_LOGOUT_SUCCESS
  }
};
export const getreferencelist = (page) => {
  return {
      type: TYPE.BOOK_API_CALL,
      page
  }
};
export const getuserlist = (pageno,search_key) => {
  return {
      type: TYPE.USER_API_CALL,
      pageno,search_key
  }
};
export const searchBook = (value) => {
  return {
      type: TYPE.SEARCH_SUCCESS,
      value
  }
};
export const userSearch = (search_key) => {
  return {
      type: TYPE.USER_SEARCH,
      search_key
  }
};
export const createReference = (refData) => {
  return {
      type: TYPE.REFERENCECREATE,
      refData
  }
};
export const editReference = (refData) => {
  return {
      type: TYPE.REFERENCEEDIT,
      refData
  }
};
export const ContributorUserEditAction = (userData) => {
  return {
      type: TYPE.CONTRIBUTOREDIT,
      userData
  }
};
export const searchBookbyIsbn = (e,page) => {
  return {
      type: TYPE.SEARCH_ISBN_SUCCESS, 
      e,page 
  }
};
export const searchBookbyIsapprove = (e,page) => {
  return {
      type: TYPE.SEARCH_ISAPPROVED_SUCCESS, 
      e,page 
  }
};
export const searchBookbyUnapprove = (e,page) => {
  return {
      type: TYPE.SEARCH_UNAPPROVED_SUCCESS,
      e,page  
  }
};
export const setLoading = (data) => {
  return {
      type: TYPE.SHOW_LODER,
      isLoading: data
  }
};
export const ContributorUserAction = (userData) => {
  return {
      type: TYPE.CONTRIBUTORCREATE,
      userData
  }
};
export const ContributorDeleteAction = (ids) => {
  return {
      type: TYPE.CONTRIBUTORDELETE,
      ids
  }
};
export const ReferenceDeleteAction = (data) => {
  return {
      type: TYPE.REFERENCEDELETE,
     data
  }
};
export const ContributorMultipleDeleteAction = (ids) => {
    return {
        type: TYPE.CONTRIBUTORDELETEMULTIPLE,
        ids
    }
};
