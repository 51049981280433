import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../../actions/constants';
import { apiUrl } from '../../environment/index';
import axios from "axios";
import withAuth from '../withAuth';

const UserActionService = (userData) => axios.post(`${apiUrl}admin/contributor?name=${userData.name}&email=${userData.email.toLowerCase()}&password=${userData.password}&allow_to_approve_reference=${userData.allow_to_approve_reference}&allow_to_add_contributor=${userData.allow_to_add_contributor}`,'',{headers : withAuth()});

const getUsers = (pagenumber) => axios.get(`${apiUrl}admin/contributor?page=${pagenumber.pageno}&search_key=${pagenumber.search_key}`, {headers : withAuth()});

const editUser = (userData) => axios.put(`${apiUrl}admin/contributor/${userData.id}?name=${userData.name}&email=${userData.email}&allow_to_approve_reference=${userData.allow_to_approve_reference}&allow_to_add_contributor=${userData.allow_to_add_contributor}`,'',{headers : withAuth()});

const deleteUser = (ids) => axios.post(`${apiUrl}admin/delete`,ids,{headers : withAuth()});

const deleteMultipleUser = (ids) => axios.post(`${apiUrl}admin/delete`,ids ,{headers : withAuth()});



export function* UserActionSaga({userData}) {
  try{
    yield put({ type: types.SHOW_LODER, isLoading: true});
    const response = yield call(UserActionService, userData);
    if(response.data.status == 200){

      const pagenumber = {
        pageno:1,
        search_key:''
      }
      const getdata = yield call(getUsers,pagenumber);
      if(getdata.data.status == 200){
        yield put({ type: types.PAGE_NUMBER, pageno: pagenumber.pageno });
        yield put({type: types.SET_LIST_USERS, users: getdata.data.result.data});
        yield put({ type: types.USER_ERROR_MSG, errormsg : false });
      }
      yield put({ type: types.SHOW_LODER, isLoading: false});
      //yield put({ type: types.CONTRIBUTORCREATE, userData: response.data});
    }
    else if(response.data.status == 400){
      yield put({ type: types.SHOW_LODER, isLoading: false});
      yield put({ type: types.USER_ERROR_MSG, errormsg : true });
    }
    else{
      yield put({ type: types.SHOW_LODER, isLoading: false});
      yield put({ type: types.USER_NOT_FOUND, response });
    }
  }catch (error) {
    yield put({ type: types.USER_NOT_FOUND, error });
  }
}

export function* getUserSaga(pagenumber) {
  try {
    const response = yield call(getUsers,pagenumber);
    if (response.data.status == 200) {
        yield put({type: types.SET_LIST_USERS, users: response.data.result.data});
        yield put({ type: types.SHOW_LODER, isLoading: false }); 
        } 
      else {
        yield put({ type: types.SHOW_LODER, isLoading: false });
        yield put({ type: types.USER_NOT_FOUND, error:true  });
      }
  } catch (error) {
    yield put({ type: types.USER_NOT_FOUND, error  });
  }
}

function* editUserSaga({userData}) 
{
  try {
    yield put({ type: types.SHOW_LODER, isLoading: true});
    const response = yield call(editUser, userData);
        if(response.data.status == 200)
        {
          // yield put({ type: types.SHOW_LODER, isLoading: true});
          const pagenumber = {
            pageno:1,
            search_key:''
          }
          const getdata = yield call(getUsers,pagenumber);
          if(getdata.data.status == 200){
            yield put({type: types.SET_LIST_USERS, users: getdata.data.result.data});
            yield put({ type: types.PAGE_NUMBER, pageno: pagenumber.pageno });
          }
          // yield put({type: types.USER_UPDATE_SUCCESS, users: response.data.result.data}); 
          yield put({ type: types.SHOW_LODER, isLoading: false});
        }
        else if(response.data.status == 401){
          yield put({ type: types.SHOW_LODER, isLoading: false});
        }
        else
        {
          yield put({ type: types.SHOW_LODER, isLoading: false});
          yield put({ type: types.USER_UPDATE_ERROR, response});
        }
        
    } catch (error) {
      yield put({ type: types.USER_UPDATE_ERROR, error});
    }
}

function* deleteUserSaga(ids) {
  try 
  {
    yield put({ type: types.SHOW_LODER, isLoading: true});
      const response = yield call(deleteUser,ids);
      if(response.data.status == 200)
      {
        const pagenumber = {
          pageno:1,
          search_key:''
        }
        const getdata = yield call(getUsers,pagenumber);
        if(getdata.data.status == 200){
          yield put({ type: types.DELETE_SUCCESS, success:true });
          yield put({type: types.SET_LIST_USERS, users: getdata.data.result.data});
          yield put({ type: types.PAGE_NUMBER, pageno: pagenumber.pageno });
        }
        yield put({ type: types.SHOW_LODER, isLoading: false}); 
      } 
      else 
      {
        yield put({ type: types.SHOW_LODER, isLoading: false});
        yield put({ type: types.DELETE_ERROR, response });
      }
  }
  catch (error) {
    yield put({ type: types.DELETE_ERROR, error });
  }
}

function* deleteMultipleUserSaga(ids) {
  try 
  {
    yield put({ type: types.SHOW_LODER, isLoading: true});
      const response = yield call(deleteMultipleUser,ids);
      if(response.data.status == 200)
      {
        const pagenumber = {
          pageno:1,
          search_key:''
        }
        const getdata = yield call(getUsers,pagenumber);
        if(getdata.data.status == 200){
          yield put({ type: types.DELETE_SUCCESS, success:true });
          yield put({type: types.SET_LIST_USERS, users: getdata.data.result.data});
          yield put({ type: types.PAGE_NUMBER, pageno: pagenumber.pageno });
        }
        yield put({ type: types.SHOW_LODER, isLoading: false});
      } 
      else 
      {
        yield put({ type: types.SHOW_LODER, isLoading: false});
        yield put({ type: types.DELETE_SUCCESS, success:false });
        yield put({ type: types.DELETE_ERROR, response });
      }
  }
  catch (error) {
    yield put({ type: types.DELETE_ERROR, error });
  }
}


function* watchUserAuthentication() {
  yield all([takeLatest(types.CONTRIBUTORCREATE, UserActionSaga)]);
  yield all([takeLatest(types.USER_API_CALL, getUserSaga)]);
  yield all([takeLatest(types.CONTRIBUTOREDIT, editUserSaga)]);
  yield all([takeLatest(types.CONTRIBUTORDELETE, deleteUserSaga)]);
  yield all([takeLatest(types.CONTRIBUTORDELETEMULTIPLE, deleteMultipleUserSaga)]);

}

export default watchUserAuthentication;