import React from "react";
import Spinners from "../../img/easy.png";

const FullPageLoader = () => {
    return (<>
        <div className="loader">
            <div className="fp-container">
                <img src={Spinners} className="fp-loader" alt="loading" style={{ width: '6rem', height: '6rem' }} />
            </div>
        </div>
    </>
    );
};

export default FullPageLoader;