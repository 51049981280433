import { combineReducers } from 'redux';
import loginReducer from '../components/LoginForm/login.reducer';
import bookReducer from '../components/Book/book.reducer';
import contributorReducer from '../components/Contributor/contributor.reducer';
import book_refReducer from '../components/Book/book_ref.reducer';
import usersReducer from '../components/Contributor/users.reducer';
const rootReducer = combineReducers({
   login : loginReducer,
   Reference : bookReducer,
   book_ref : book_refReducer,
   contributor : contributorReducer,
   Users : usersReducer
  });
  
  export default rootReducer;