import * as types from '../../actions/constants';

const initialState = {
    token: 'HaGBYHqP4sbXght57wBfgNTkFf9JPDeH',
    
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.USER_LOGIN:            
            return { ...state, cread: action.user};
        case types.USER_LOGIN_SUCCESS:
            return { ...state, user: action.user, cread: null };
        case types.USER_LOGIN_ERROR:
            return { ...state, response: action.error };
        case types.SHOW_LODER:
            return { ...state, isLoading: action.isLoading };
        case types.SHOW_LODER:
            return { ...state, isLoading: action.isLoading };
        case types.USER_LOGOUT_SUCCESS:
            return { ...state, user:null};
        default:
            return state;
    }
}