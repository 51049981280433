import {createStore,applyMiddleware} from 'redux';
import rootReducer from "./rootReducer";
import {rootSaga} from "./rootSaga";

import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();

/* Mount it on the Store */
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

/* Run the saga */
sagaMiddleware.run(rootSaga);

//let store = createStore(rootReducer);

export default store;