import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../../actions/constants';
import { apiUrl } from '../../environment/index';
import axios from "axios";
import history from "../../history";
import withAuth from '../withAuth';

const loginUserService = (user) => axios.post(`${apiUrl}admin/login`,user.user);
const logoutUser = () => axios.post(`${apiUrl}admin/logout`, '', {headers : withAuth()});

function redirectToPage(location) {
  history.push(location);
}

export function* loginSaga(user) {
  try{
    const response = yield call(loginUserService, user);
    if(response.data.status == 200){
        localStorage.setItem('token', JSON.stringify(response.data.result.token));
        localStorage.setItem('is_admin', JSON.stringify(response.data.result.userdata.is_admin));
        localStorage.setItem('allow_to_approve_reference', JSON.stringify(response.data.result.userdata.allow_to_approve_reference));
        yield put({ type: types.USER_LOGIN_SUCCESS,user: response.data.result});
    }
    else{
        yield put({ type: types.SHOW_LODER, isLoading: false });
        yield put({ type: types.USER_LOGIN_ERROR, error:true });
        yield call(redirectToPage, '/login',response);
    }
  }catch (error) {
    yield put({ type: types.USER_LOGIN_ERROR, error })
  }
}

export function* logoutSaga() {
  try 
  {
    yield put({ type:  types.SHOW_LODER, isLoading: true});
      const response = yield call(logoutUser);
      if(response.status == 200)
      {
        yield put({ type: types.LOG_OUT, response});
        yield put({ type: types.SHOW_LODER, isLoading: false}); 
      } 
      else 
      {
        yield put({ type:  types.SHOW_LODER, isLoading: false});
      }
  }
  catch (error) {
    yield put({ type: types.USER_LOGOUT_ERROR, error });
  }
}

function* watchUserAuthentication() {
  yield all([takeLatest(types.USER_LOGIN, loginSaga)]);
  yield all([takeLatest(types.USER_LOGOUT_SUCCESS, logoutSaga)]);
}
export default watchUserAuthentication;