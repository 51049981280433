/*
 * @file: constants.js
 * @description: It contain action types related action.
 * @author: Author
 */

/*********** Login ***********/
export const USER_LOGIN = 'USER_LOGIN';
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_RESET_ERROR = 'USER_RESET_ERROR';
export const USER_LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'LOGIN_USER_ERROR';
export const LOG_OUT = 'LOG_OUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';
/*********** Login ***********/


/******** Book ***********/
export const BOOK_API_CALL = 'BOOK_API_CALL';
export const BOOK_REF = 'BOOK_REF';

export const GET_STATS = 'GET_STATS';
export const SET_STATS = 'SET_STATS';
export const SEARCH_ISAPPROVED_SUCCESS = 'SEARCH_ISAPPROVED_SUCCESS';
export const SEARCH_UNAPPROVED_SUCCESS = 'SEARCH_UNAPPROVED_SUCCESS';
export const REFERENCEDELETE = 'REFERENCEDELETE';
export const START_NUMBER = 'START_NUMBER';
export const SEARCH_SUCCESS='SEARCH_SUCCESS';
export const SEARCH_NOT_FOUND='SEARCH_NOT_FOUND';
export const SEARCH_ISBN_SUCCESS = 'SEARCH_ISBN_SUCCESS';
export const SEARCH_ISBN_ERROR = 'SEARCH_ISBN_ERROR';
export const REFERENCECREATE = 'REFERENCECREATE';
export const REFERENCEERROR = 'REFERENCEERROR';
export const REFERENCEEDIT = 'REFERENCEEDIT';
export const GET_LIST_BOOK = 'GET_LIST_BOOK';
/******** Book ***********/

/******** User ***********/
export const GET_RESPONSE = 'GET_RESPONSE';
export const USER_API_CALL = 'USER_API_CALL';
export const GET_LIST_USERS = 'GET_LIST_USERS';
export const SET_LIST_USERS = 'SET_LIST_USERS';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_ERROR_MSG = 'USER_ERROR_MSG';
export const PAGE_NUMBER = 'PAGE_NUMBER';
export const CONTRIBUTORCREATE = 'CONTRIBUTORCREATE';
export const CONTRIBUTOREDIT = 'CONTRIBUTOREDIT';
export const CONTRIBUTORDELETE = 'CONTRIBUTORDELETE';
export const CONTRIBUTORDELETEMULTIPLE = 'CONTRIBUTORDELETEMULTIPLE';
export const LIST_USERS = 'LIST_USERS';
export const USER_SEARCH='USER_SEARCH';
export const SET_STATS_TOTAL='SET_STATS_TOTAL';
/******** User ***********/

/******** Common ***********/
export const DELETE_ERROR = 'DELETE_ERROR';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
/******** Common ***********/

/************ LOADING *************/
export const IS_LOADING = 'IS_LOADING';
export const SHOW_LODER = 'SHOW_LODER';
export const HIDE_LODER = 'HIDE_LODER';
/************ LOADING *************/

/************ COUNTER *************/
export const ISBN_COUNT = 'ISBN_COUNT';
export const APPROVED_COUNT = 'APPROVED_COUNT';
export const UNAPPROVED_COUNT = 'UNAPPROVED_COUNT';
/************ COUNTER *************/














