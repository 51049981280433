import React, { useState } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useLocation, Redirect } from "react-router-dom";
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import Easy from "../../img/easy.png";
import { useTheme, makeStyles } from '@material-ui/core/styles';
import history from "../../history";
import { useDispatch, connect } from 'react-redux';
import * as types from '../../actions/constants';
import { logoutAction } from "../../actions";

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex', padding: '15px 15px', color: '#fff !important', fontFamily: 'Poppins, sans-serif', fontWeight: '500', textDecoration: 'none', transition: '1s ease-out',
    "&:hover, &:focus": {
      backgroundColor: '#8dbbdb',
      boxShadow: '0 2px 8px 0 rgb(0 0 0 / 8%)',
    }
  },
  icon: { margin: '0px 10px 0px 5px !important' },
  sideBarHeight: { height: "100vh", background: '#84abc6 !important', width: '240px', boxShadow: '2px 0 5px 0 rgb(57 63 72 / 30%)', zIndex: '9999' },
  sideBarWLogin: { height: "100vh", width: '100px' },
  menuIcon: { float: "right", margin: "10px", },
  logo: { textAlign: 'center', marginTop: '20px', borderRadius: '50px' }
}));
//sideBarHeight: { height: "calc(100vh - 60.5px)" },

const Sidebar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const is_admin = localStorage.getItem('is_admin');
  const [collapsed, setCollapsed] = useState(false);
  const onClickMenuIcon = () => {
    setCollapsed(!collapsed);
  };

  function logout() {
    dispatch(logoutAction());   
    dispatch({ type: types.USER_LOGIN_SUCCESS, user: null })
    localStorage.clear();
    localStorage.removeItem("token");
    dispatch({ type: types.START_NUMBER, page: 1 });
  }


  const location = useLocation();
  if (location.pathname == '/login')
  return (<div className={classes.sideBarWLogin}></div>)
  else
    return (
      <div className={classes.sideBarHeight} collapsed={collapsed}>
        {!localStorage.getItem('token') ? <Redirect to='/login' /> : ""}
        <div className={classes.menuIcon} onClick={onClickMenuIcon}></div>
        <div iconshape="square">
          <div class="img_div">
            <img src={Easy} width={100} alt="logo" className={classes.logo}></img>
          </div>
          <hr className="line_class" />
          <div>
            <Link className={classes.link} key="reference" to={{
              pathname: "/reference"
            }}><DashboardIcon className={classes.icon} /> Dashboard</Link>
            {is_admin == 'true' ? <Link className={classes.link} key="contributor" to={{
              pathname: "/users"
            }}><GroupIcon className={classes.icon} /> Contributor</Link> : ""}
            <Link className={classes.link} onClick={logout} key="logout" to={{
              pathname: "/login"
            }}><svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" /></svg> Logout</Link>
          </div>
        </div>
      </div>
    );
};

const mapStateToProps = (state) => {
  const { Sidebar } = state;
  return { Sidebar };
};
export default connect(mapStateToProps, null)(Sidebar);