import * as types from '../../actions/constants';

const initialState = {
    token: 'HaGBYHqP4sbXght57wBfgNTkFf9JPDeH',
    
};
export default (state = {pageno:1,success:null,getres:null}, action) => {
        switch (action.type) {
        case types.GET_LIST_USERS:
                return { ...state, listuser: action.listuser};
        case types.SET_LIST_USERS:
                return { ...state, users: action.users};
        case types.PAGE_NUMBER:
                return { ...state,pageno:action.pageno};
        case types.LIST_USERS:
                return { ...state, listusers: action.listusers};
        case types.DELETE_SUCCESS:
                return { ...state, success: action.success};
        case types.GET_RESPONSE:
                return { ...state, getres: action.getres};
        case types.SHOW_LODER:
                return { ...state, isLoading: action.isLoading };
        default:
            return state;
    }
}