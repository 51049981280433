import { connect } from 'react-redux';
import { TextField, Button, Switch, Card, CardHeader, Typography, CardContent, FormControlLabel, Checkbox, CardMedia, FormControl, FormLabel, Divider } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HouseIcon from '@material-ui/icons/House';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { useEffect, useState, Component } from 'react';
import { getreferencelist, searchBook, searchBookbyIsbn, searchBookbyIsapprove, searchBookbyUnapprove, createReference, editReference, setLoading, ReferenceDeleteAction } from "../../actions/index";
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import SearchBar from "material-ui-search-bar";
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Easy from "../../img/easy.png";
import "../Book/book.css";
import { Col, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FullPageLoader from '../FullPageLoader/loader';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { SEARCH_NOT_FOUND, SHOW_LODER, START_NUMBER, GET_LIST_BOOK,UNAPPROVED_COUNT } from '../../actions/constants';
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: 'calc(100% - 120px)',
		border: '1px solid rgba(24, 39, 47, 0.1)',
		borderRight: '0px',
		borderRadius: '15px 0 0 15px',
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		width: 120,
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	playIcon: {
		height: 38,
		width: 38,
	},
	addBookBtn: {
		backgroundColor: '#75859a', color: '#fff', fontFamily: 'Poppins, sans-serif',
		"&:hover, &:focus": {
			backgroundColor: '#75859a',
			//backgroundColor: '#444f5b',
			fontFamily: 'Poppins, sans-serif',
		}
	}
}));

// For Datepicker
const popperModifiers =
	[
		{
			name: "offset",
			options: {
				offset: [5, 10],
			},
		},
		{
			name: "preventOverflow",
			options: {
				rootBoundary: "viewport",
				tether: false,
				altAxis: true,
				preventOverflow: {
					enabled: true
				},
				flip: {
					behavior: ['top'] // don't allow it to flip to be above
				}
			},
		}
	];

export class Reference extends Component {
	render() {
		const data = { ...this.props, ...this.state };
		return (<Ref {...data} />);
	}
}

const Ref = (props) => {
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false); // Add modal event
	const [editopen, editsetOpen] = React.useState(false); // Edit modal event
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const users = useSelector(state => state.Reference); // Reducer data of reference
	const allow_to_approve_reference = localStorage.getItem('allow_to_approve_reference'); // For local storage constant
	const [search_value, setsearch_data] = useState(""); // For Search Data
	const [isbnchecked, setisbnChecked] = useState(false); // For checkbox of isbnonly while add/edit
	const [is_approved_checked, setisapprovedchecked] = useState(false); // For checkbox of isbnonly
	const [is_approvedchecked, setIsApprovedChecked] = useState(false); // For checkbox of isapproved
	const [disapprovedchecked, setDisapprovedChecked] = useState(false); // For checkbox of unapproved
	const [value, setQuery] = useState("");
	const classes = useStyles();
	const bull = <span className={classes.bullet}>•</span>;
	const itemsPerPage = 10; // For Pagination item per page
	const [page, setPage] = React.useState(1); // For pagination
	const [pageSize, setpageSize] = React.useState(30); // For pagination
	const [hasMore, setHasMore] = useState(true);// Infinite scroll hasmore event
	const paginate_data = users && users.stats && users.stats.length;
	const [apionce, setOnce] = useState(""); // Prevent to call multiple time get ref api
	// const final_data = paginate_data !== undefined ? Math.ceil(paginate_data / itemsPerPage) : "";
	// const [noOfPages] = React.useState(final_data);
	const [formData, updateFormData] = useState([]); // For add/update data 
	const [check_online, setcheck_online] = useState(false) // For switch button of is book online
	const [titleError, setTitleError] = useState('') // For book title error
	const [datepublishedError, setDatePublishedError] = useState('') // For date published error
	const [dateaccessedError, setDateAccessedError] = useState('') // For date accessed error
	const [urlError, setURLError] = useState('') // For url error
	const [publisherError, setPublisherError] = useState('') // For publisher error
	const [publisherplaceError, setPublisherPlaceError] = useState('') // For publisher place error
	const [isbnError, setIsbnError] = useState('') // For isbn error
	const [authorFirstnameError, setAuthorFirstNameError] = useState('') // For author error
	const [authorLastnameError, setAuthorLastNameError] = useState('') // For author error
	const [authorTitleError, setAuthorTitleError] = useState('') // For author error
	const [date_published, setDate_p] = useState(null); // For date published date constant
	const [date_originally_published, setDate_o] = useState(null); // For date orginally constant
	const [date_accessed_viewed, setDate_a] = useState(null); // For date accessed viewed date
	const is_admin = localStorage.getItem('is_admin'); // For isadmin local storage constant
	const [deleteopen, deletesetOpen] = React.useState(false); // delete modal
	const [bookdata, setBookdata] = useState([]); // For update book data for pagination
	const [lengthsize, setSizeLength] = useState(""); // For update book data for pagination
	const [loadingState, setloadingState] = useState(false); // For loading state of loader message
	const [loadingMessage, setloadingMessage] = useState(''); // For update message
	const [approveState, setApproveState] = useState(false); // For loading state of loader message
	const [isbnState, setIsbnState] = useState(false); // For loading state of loader message
	const [unapproveState, setUnapproveState] = useState(false); // For loading state of loader message

	const handleClose_ref = () => {
		setInputList([{ given: "", family: "", hide: true, literal: '' }]);
		seteditorList([{ given: "", family: "", hide: true, literal: '' }]);
		settransList([{ given: "", family: "", hide: true, literal: '' }]);
		updateFormData([])
		seteditor_show('d-none')
		settrans_show('d-none')
		setcheck_online(false)
		setIsbnOnlineChecked(false)
		setDate_p(null)
		setDate_a(null)
		setDate_o(null)
		seteditor_show_b('btn btn-info')
		settrans_show_b('btn btn-info')
	}

	
	useEffect(() => {
		if (props.books) {
			if (props.books.length === props.total) {
				setloadingState(false);
				setloadingMessage("Yay! You have seen it all")
				setTimeout( () => {
					setloadingMessage("")
				}, 5000);
			}
			//setSizeLength(props && props.books && props.books.length);
		}
	}, [props.books])
	useEffect(() => {
		if (props.stats) {
			setBookdata(props.stats)
		}
	}, [props.stats])
	useEffect(() => {
		if (props.stats) {
			if (props.books.length >= 15) {
				var refdata = props.books ? props.books : [];
				var updaterefdata = bookdata;
				props.dispatch({ type: GET_LIST_BOOK, books: [...refdata, ...updaterefdata] });
			}
			else {
				props.dispatch({ type: GET_LIST_BOOK, books: bookdata });
			}
		}
	}, [bookdata])
	//End :: Pagination data update code

	//Start :: Get Reference List 
	useEffect(() => {
		if (users.stats != null && users.stats != undefined && paginate_data !== 0) {
			dispatch(setLoading(false))
		}
		else {
			dispatch(setLoading(true))
		}
		props.dispatch({ type: SEARCH_NOT_FOUND, searcherrormsg: null });
		props.dispatch({ type: GET_LIST_BOOK, books: [] });
		dispatch(getreferencelist(props.page));
		props.dispatch({ type: START_NUMBER, page: props.page });
	}, [apionce])

	//End :: Get Reference List

	//Start :: Pagination data update code
	// useEffect(() => {
	// 	console.log(props.isbn_count)
	// 	dispatch(searchBookbyIsbn("", 1));
	// 	dispatch(searchBookbyIsapprove("", 1));
	// 	dispatch(searchBookbyUnapprove("", 1));
	// },[])

	// Start :: Modal open & close event
	const handleClickOpen = () => {
		setOpen(true);
		setTitleError('');
		setDateAccessedError('');
		setPublisherError('');
		setPublisherPlaceError('');
		setURLError('');
		setAuthorFirstNameError('');
		setAuthorLastNameError('');
		setAuthorTitleError('');
		setDatePublishedError('');
		handleClose_ref('');
	};

	const handleClose = () => {
		setOpen(false);
		handleClose_ref('');
		setTitleError('');
		setDateAccessedError('');
		setPublisherError('');
		setPublisherPlaceError('');
		setURLError('');
		setAuthorFirstNameError('');
		setAuthorLastNameError('');
		setAuthorTitleError('');
		setDatePublishedError('');
	};
	// End :: Modal open & close event

	//Start :: Define constant of isbn,approved,unapporved,edit book online switch disable event
	const [checkUnApproveDisable, checkUnApproveIsDisable] = useState('')
	const [checkApproveDisable, checkApproveIsDisable] = useState('')
	const [checkIsbnDisable, checkIsbnIsDisable] = useState('')
	const [editAdminApproveDisable, checkEditAdminApproveDisable] = useState('')
	const [editAdminButtonApproveDisable, checkEditAdminButtonApproveDisable] = useState('')
	const [isbnonlinechecked, setIsbnOnlineChecked] = useState(false);
	const [edit_approved_checked, setEditCheckApprovedChecked] = useState(false);
	//End :: Define constant of isbn,approved,unapporved disable event

	//Start :: Isbn only Checked checkbox event 
	const handleCheckchange = (e) => {
		setloadingMessage("")
		e.preventDefault();
		props.dispatch({ type: GET_LIST_BOOK, books: [] });
		setisbnChecked(!isbnchecked)
		if (!isbnchecked) {
			setloadingMessage("")
			disapprovedchecked === false || disapprovedchecked === true ? setDisapprovedChecked(false) : setDisapprovedChecked(true);
			is_approved_checked === false || is_approved_checked === true ? setisapprovedchecked(false) : setisapprovedchecked(true);
			// checkApproveIsDisable(is_approved_checked == false ? true : false)
			// checkUnApproveIsDisable(disapprovedchecked == false ? true : false)
			var page = 1;
			dispatch(searchBookbyIsbn(e, page));
			props.dispatch({ type: START_NUMBER, page: page });
			setTimeout(() => {
				setIsbnState(true);
			}, 1000);
		} else {
			checkApproveIsDisable(is_approved_checked == true ? true : false)
			checkUnApproveIsDisable(disapprovedchecked == true ? true : false)
			dispatch(setLoading(true));
			props.dispatch({ type: SEARCH_NOT_FOUND, searcherrormsg: null });
			var page = 1;
			dispatch(getreferencelist(page));
			props.dispatch({ type: START_NUMBER, page: page });
			setloadingMessage("")
			setTimeout(() => {
			 setIsbnState(false)
			}, 1000);
		}
	};
	//End :: Isbn only Checked checkbox event

	const handleisbnonlinechange = (event) => {
		setIsbnOnlineChecked(!isbnonlinechecked)
		updateFormData({
			...formData,
			"URL": ''
		});
		setDate_a(null)
	}

	// Start :: Approved Checked checkbox event 
	const handleApprovedchange = (e) => {
		setloadingMessage("")
		e.preventDefault();
		props.dispatch({ type: GET_LIST_BOOK, books: [] });
		setisapprovedchecked(!is_approved_checked)
		if (!is_approved_checked) {
			setloadingMessage("")
			isbnchecked === false || isbnchecked === true ? setisbnChecked(false) : setisbnChecked(true);
			disapprovedchecked === false || disapprovedchecked === true ? setDisapprovedChecked(false) : setDisapprovedChecked(true);
			// checkIsbnIsDisable(isbnchecked == false ? true : false)
			// checkUnApproveIsDisable(disapprovedchecked == false ? true : false)
			var page = 1;
			dispatch(searchBookbyIsapprove(e, page));
			props.dispatch({ type: START_NUMBER, page: page });
			setTimeout(() => {
				setApproveState(true);
			}, 1000);

		} else {
			checkIsbnIsDisable(isbnchecked == true ? true : false)
			checkUnApproveIsDisable(disapprovedchecked == true ? true : false)
			dispatch(setLoading(true));
			props.dispatch({ type: SEARCH_NOT_FOUND, searcherrormsg: null });
			var page = 1;
			dispatch(getreferencelist(page));
			props.dispatch({ type: START_NUMBER, page: page });
			setloadingMessage("")
			setTimeout(() => {
				setApproveState(false);
		 }, 1000);
		}
	};
	// End :: Approved Checked checkbox event 

	// Book change event
	const handleCheckBookonline = (event) => {
		if (!check_online) {
			setcheck_online(event.target.checked);
		}
		else {
			setDate_a(null);
			setcheck_online(false)
			setDateAccessedError('');
			setURLError('');
		}
	};
	// Book change event

	// Start :: Add/Edit event for approve checkbox
	const handleEditApprovedchange = (e) => {
		e.preventDefault();
		setEditCheckApprovedChecked(!edit_approved_checked)
	}

	const handleAddApprovedchange = (e) => {
		e.preventDefault();
		setIsApprovedChecked(!is_approvedchecked)
	};
	// End :: Add/Edit event for approve checkbox

	// Start :: Unapproved Checked checkbox event 
	const handleDisapprovedchange = (e) => {
		setloadingMessage("")
		e.preventDefault();
		props.dispatch({ type: GET_LIST_BOOK, books: [] });
		setDisapprovedChecked(!disapprovedchecked)
		if (!disapprovedchecked) {
			setloadingMessage("")
			isbnchecked === false || isbnchecked === true ? setisbnChecked(false) : setisbnChecked(true);
			is_approved_checked === false || is_approved_checked === true ? setisapprovedchecked(false) : setisapprovedchecked(true);
			var page = 1;
			dispatch(searchBookbyUnapprove(e, page));
			props.dispatch({ type: START_NUMBER, page: page });
			setTimeout(() => {
				setUnapproveState(true);
		 	}, 1000);
			

		} else {
			checkIsbnIsDisable(isbnchecked == true ? true : false)
			checkApproveIsDisable(is_approved_checked == true ? true : false)
			dispatch(setLoading(true));
			props.dispatch({ type: SEARCH_NOT_FOUND, searcherrormsg: null })
			var page = 1;
			dispatch(getreferencelist(page));
			props.dispatch({ type: START_NUMBER, page: page });
			setloadingMessage("")
			setTimeout(() => {
				setUnapproveState(false);
			}, 1000);
		}
	};
	// End :: Unapproved Checked checkbox event 

	// START :: Pagination Event
	const handlePageChange = (event, value) => {
		setPage(value);
	};

	const fetchMoreData = () => {
		setloadingState(true)
		// setloadingMessage("Loading...")
		if (isbnchecked === true && isbnState === true) {
			dispatch(searchBookbyIsbn("", props.page + 1));
			props.dispatch({ type: SHOW_LODER, isLoading: true })
			props.dispatch({ type: START_NUMBER, page: props.page + 1 });
		}
		else if (is_approved_checked === true && approveState === true) {
			dispatch(searchBookbyIsapprove("", props.page + 1));
			props.dispatch({ type: SHOW_LODER, isLoading: true })
			props.dispatch({ type: START_NUMBER, page: props.page + 1 });
		}
		else if (disapprovedchecked === true && unapproveState === true) {
			dispatch(searchBookbyUnapprove("", props.page + 1));
			props.dispatch({ type: SHOW_LODER, isLoading: true })
			props.dispatch({ type: START_NUMBER, page: props.page + 1 });
		}
		else if (disapprovedchecked === true || is_approved_checked === true || isbnchecked === true) {
			// Nothing
		}
		else {
			if(approveState === false || isbnState === false || unapproveState === false){
				props.dispatch({ type: SHOW_LODER, isLoading: true })
				dispatch(getreferencelist(props.page + 1));
				props.dispatch({ type: START_NUMBER, page: props.page + 1 });
			}
		}
	}
	// END :: Pagination Event

	// START :: Delete Modal close event
	const handleDeleteClose = () => {
		deletesetOpen(false);
	};
	// END :: Delete Modal close event

	const handleDeleteSubmit = (e) => {
		var data = {};
		data.ref_id = ref_id;
		data.disapprovedchecked = disapprovedchecked;
		data.is_approved_checked = is_approved_checked;
		data.isbnchecked = isbnchecked;
		dispatch(ReferenceDeleteAction(data));
		toast.success(" Reference data deleted successfully ", {
			position: "top-right",
			autoClose: 1000
		});
		deletesetOpen(false);
		editsetOpen(false);
	}

	// START :: Search By Name & Isbn event 
	const handleSearchbook = (e) => {
		setsearch_data(e)
	}

	const handleCloseSearch = (e) => {
		props.dispatch({ type: SHOW_LODER, isLoading: true })
		props.dispatch({ type: SEARCH_NOT_FOUND, searcherrormsg: null });
		setloadingMessage("")
		dispatch(getreferencelist(props.page));
		props.dispatch({ type: START_NUMBER, page: 1});
	}

	useEffect(() => {
		if (props.searcherrormsg === false) {
			props.dispatch({ type: SEARCH_NOT_FOUND, searcherrormsg: null });
		}
		if (props.searcherrormsg === true) {
			setloadingMessage("")
		}
	}, [props.searcherrormsg])

	const searchsubmit = (e) => {
		e.preventDefault();
		if (search_value) {
			dispatch(searchBook(search_value));
		}
		else {
			props.dispatch({ type: SHOW_LODER, isLoading: true })
			setloadingMessage("")
			props.dispatch({ type: SEARCH_NOT_FOUND, searcherrormsg: null });
			dispatch(getreferencelist(props.page));
		}
	}
	// END :: Search By Name & Isbn event 

	// START :: Date Constant for Date Published
	const DateGet_Published = () => {
		var today = new Date()
		var d = today.getDate();
		var m = today.getMonth() + 1; //Month from 0 to 11
		var y = today.getFullYear();

		var dates = '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
		setDate_p(new Date())
	}
	// END :: Date Constant for Date Published

	// START :: Date Constant for Date Access Viwed
	const DateGet_Accessed_Viewed = () => {
		var today = new Date()
		var d = today.getDate();
		var m = today.getMonth() + 1; //Month from 0 to 11
		var y = today.getFullYear();

		var dates = '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
		setDate_a(new Date())
	}
	// END :: Date Constant for Date Access Viwed

	// START :: Date Constant for Date Originally Published
	const DateGet_Originally_Published = () => {
		var today = new Date()
		var d = today.getDate();
		var m = today.getMonth() + 1; //Month from 0 to 11
		var y = today.getFullYear();

		var dates = '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
		setDate_o(new Date())
	}
	// END :: Date Constant for Date Originally Published

	// START :: Input Handle CHange Event
	const handleChange = (e) => {
		if (e.target.name === 'Date_Published') {

			setDate_p(e.target.value.trim())
		}
		else if (e.target.name === 'Date_Accessed_viewed') {

			setDate_a(e.target.value.trim())
		}
		else if (e.target.name === 'Date_Originally_Published' || e.target.name === 'date_of_event') {

			setDate_o(e.target.value.trim())
		}
		else {
			updateFormData({
				...formData,
				[e.target.name]: e.target.value
			});
		}
	};
	// END :: Input Handle CHange Event


	// START :: Add New Event for author
	const [inputList, setInputList] = useState([{ given: "", family: "", hide: true, literal: '' }]);
	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputList];
		if (name === undefined) {
			list[index]['hide'] = !list[index]['hide'];
			list[index]['given'] = '';
			list[index]['family'] = '';
			list[index]['literal'] = '';
		}
		else {
			list[index][name] = value.replace(/\b(\w)/g, s => s.toUpperCase());
		}
		setInputList(list);
	};

	const handleAddClick = () => {
		setInputList([...inputList, { given: "", family: "", hide: true, literal: '' }]);
	}
	const handleRemoveClick = (index) => {
		const list = [...inputList];
		list.splice(index, 1);
		setInputList(list);

	};
	// END :: Add New Event for author

	// START :: Add New event for editior
	const [editor_show, seteditor_show] = useState('d-none')
	const [editor_show_b, seteditor_show_b] = useState('btn btn-info')
	const editorDisplay = () => {
		seteditor_show('d-block')
		seteditor_show_b('d-none')
	}
	const [editorList, seteditorList] = useState([{ given: "", family: "", hide: true, literal: '' }]);
	const handleEditorChange = (e, index) => {
		const { name, value } = e.target;
		const editor = [...editorList];
		if (name === undefined) {
			editor[index]['hide'] = !editor[index]['hide'];
			editor[index]['given'] = '';
			editor[index]['family'] = '';
			editor[index]['literal'] = '';
		}
		else {
			editor[index][name] = value.replace(/\b(\w)/g, s => s.toUpperCase());
		}
		seteditorList(editor);
	};
	// Add event
	const handleeditorAddClick = () => {
		seteditor_show('d-block')
		seteditor_show_b('d-none')
		seteditorList([...editorList, { given: "", family: "", hide: true, literal: '' }]);
	}
	// Remove event
	const handleeditorRemoveClick = index => {
		const editor = [...editorList];
		if (editor.length === 1) {
			seteditor_show('d-none')
			seteditor_show_b('btn btn-info')
		}
		else {
			editor.splice(index, 1);
		}
		seteditorList(editor);
	};
	const handleediteditorRemoveClick = index => {
		const editor = [...editorList];
		if (editor.length === 1) {
			seteditor_show('d-none')
			seteditor_show_b('btn btn-info')
			seteditorList([{ given: "", family: "", hide: true, literal: '' }]);
		}
		else {
			editor.splice(index, 1);
			seteditorList(editor);
		}
	}
	// END :: Add New event for editior

	// START :: Add new event for translator 
	const [trans_show, settrans_show] = useState('d-none')
	const [trans_show_b, settrans_show_b] = useState('btn btn-info edit_btn_translator')
	const transDisplay = () => {
		settrans_show('d-block')
		settrans_show_b('d-none')
	}

	const [transList, settransList] = useState([{ given: "", family: "", hide: true, literal: '' }]);
	const handletransChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...transList];
		if (name === undefined) {
			list[index]['hide'] = !list[index]['hide'];
			list[index]['given'] = '';
			list[index]['family'] = '';
			list[index]['literal'] = '';
		}
		else {
			list[index][name] = value.replace(/\b(\w)/g, s => s.toUpperCase());
		}
		settransList(list);
	};
	// Remove 
	const handletransRemoveClick = index => {
		const trans = [...transList];
		if (trans.length === 1) {
			settrans_show('d-none')
			settrans_show_b('btn btn-info edit_btn_translator')
		}
		else {
			trans.splice(index, 1);
		}

		settransList(trans);
	};
	const handleedittransRemoveClick = index => {
		const trans = [...transList];
		if (trans.length === 1) {
			settrans_show('d-none')
			settrans_show_b('btn btn-info edit_btn_translator')
			settransList([{ given: "", family: "", hide: true, literal: '' }]);
		}
		else {
			trans.splice(index, 1);
			settransList(trans);
		}

	}
	const handletransClick = () => {
		settransList([...transList, { given: "", family: "", hide: true, literal: '' }]);
	}
	// END :: Add new event for translator 

	const handleRefDeleteClick = (ref_id,isbnchecked,is_approved_checked,disapprovedchecked) => {
		deletesetOpen(true);
	}

	// START :: constant declaration for Edit fucntionality 
	const [book_title_desc, settitle] = useState("");
	const [isbn_number, setisbn] = useState("");
	const [ref_id, setrefid] = useState("");
	const [is_approved_data, setisapproved] = useState("");
	// END :: constant declaration for Edit fucntionality 

	// START :: edit modal get data event 
	const handleEditClick = (id, title, isbn, data, is_approved) => {
		if (data == null || data == '') {
			editsetOpen(true);
			setisbn(isbn);
			settitle(title);
			setisbn(isbn);
			setrefid(id);
		}
		else {
			editsetOpen(true);
			// const datas = (JSON.parse(data));
			const referenceupdate = (JSON.parse(data));
			setisapproved(is_approved);
			updateFormData(
				referenceupdate
			);
			settitle(title);
			setisbn(isbn);
			setrefid(id);
			setEditCheckApprovedChecked(is_approved == "1" ? true : false)
			checkEditAdminButtonApproveDisable(is_approved === "1" && is_admin != "true")
			checkEditAdminApproveDisable(is_approved === "1" && (is_admin != "true" || is_admin == "true") ? true : false)
			if (referenceupdate.issued) {
				if (referenceupdate.issued['date-parts']) {
					var ab = referenceupdate.issued['date-parts'][0];
					setDate_p(new Date(ab[0].toString() + '-' + ab[1].toString() + '-' + ab[2].toString()))
				}
			}
			if (referenceupdate['original-date']) {
				if (referenceupdate['original-date']['date-parts'].length !== 0) {
					var ab = referenceupdate['original-date']['date-parts'][0];
					setDate_o(new Date(ab[0].toString() + '-' + ab[1].toString() + '-' + ab[2].toString()))
				}
			}
			if (referenceupdate['event-date']) {
				if (referenceupdate['event-date']['date-parts'].length !== 0) {
					var ab = referenceupdate['event-date']['date-parts'][0];
					setDate_o(new Date(ab[0].toString() + '-' + ab[1].toString() + '-' + ab[2].toString()))
				}
			}
			if (referenceupdate['accessed']) {
				if (referenceupdate['accessed']['date-parts'].length !== 0) {
					var ab = referenceupdate['accessed']['date-parts'][0];
					setDate_a(new Date(ab[0].toString() + '-' + ab[1].toString() + '-' + ab[2].toString()))
				}
			}
			if ((referenceupdate['URL'] !== undefined) && (referenceupdate['accessed'] !== undefined)) {
				setIsbnOnlineChecked(true)
			}
			if (referenceupdate) {
				const arr = referenceupdate.author
				for (let i = 0; i < arr.length; i++) {
					if (arr[i]['literal'] !== '' || referenceupdate.author.length == 0) {
						arr[i].hide = false
					}
					else {
						arr[i].hide = true
					}
				}
				setInputList(arr)
				if (referenceupdate.editor) {
					if (referenceupdate.editor !== '' || referenceupdate.editor.length == 0) {
						const arr1 = referenceupdate.editor
						for (let i = 0; i < arr1.length; i++) {
							if (arr1[i]['literal'] !== '') {
								arr1[i].hide = false
							}
							else {

								arr1[i].hide = true
							}
						}
						seteditor_show('d-block')
						seteditor_show_b('d-none')
						seteditorList(arr1)
					}
				}
				if (referenceupdate.translator) {
					if (referenceupdate.translator !== '' || referenceupdate.translator.length == 0) {
						const arr2 = referenceupdate.translator
						for (let i = 0; i < arr2.length; i++) {
							if (arr2[i]['literal'] !== '') {
								arr2[i].hide = false
							}
							else {

								arr2[i].hide = true
							}
						}
						settrans_show('d-block')
						settrans_show_b('d-none')
						settransList(arr2)
					}
				}
			}
		}
	};
	// END :: edit modal get data event

	// START :: edit modal close event 
	const handleEditClose = () => {
		setURLError('');
		setTitleError('');
		setDateAccessedError('');
		setPublisherError('');
		setPublisherPlaceError('');
		setAuthorFirstNameError('');
		setAuthorLastNameError('');
		setAuthorTitleError('');
		setDatePublishedError('');
		handleClose_ref();
		editsetOpen(false);
	};
	// END :: edit modal close event 

	// START :: fucntion to update book reference data
	const handleEditSubmit = (e) => {
		var id = ref_id;
		var title = book_title_desc ? book_title_desc : "";
		var author = inputList[0].given !== '' ? inputList[0].given : '';
		var isbn = isbn_number ? isbn_number : "";
		var is_approved = edit_approved_checked == true ? 1 : 0;
		var Date_Published = date_published != null ? moment(date_published).format("YYYY-MM-DD").split("-") : '';
		var Date_Accessed = date_accessed_viewed != null ? moment(date_accessed_viewed).format("YYYY-MM-DD").split("-") : '';
		var Date_Originally_Published = date_originally_published != null ? moment(date_originally_published).format("YYYY-MM-DD").split("-") : '';
		var Data = {
			"id": "item-1",
			"type": "book",
			"author": inputList[0].given !== '' || inputList[0].literal !== '' ? inputList : '',
			"editor": editorList[0].given !== '' || editorList[0].literal !== '' ? editorList : '',
			"translator": transList[0].given !== '' || transList[0].literal !== '' ? transList : '',
			"title": title,
			"issued": { "date-parts": [Date_Published] },
			"URL": formData.URL ? formData.URL : '',
			"annote": formData.annote ? formData.annote : '',
			"volume": formData.volume ? formData.volume : '',
			"ISBN": isbn ? isbn : '',
			"publisher-place": formData['publisher-place'] ? formData['publisher-place'] : '',
			"archive-place": formData['publisher-place'] ? formData['publisher-place'] : '',
			"publisher": formData.publisher ? formData.publisher : '',
			"edition": formData.edition ? formData.edition : '',
			"locator": formData.Page ? formData.Page : '',
			"page": formData.page ? formData.page : '',
		}
		localStorage.setItem('unapprove_flag',disapprovedchecked === true ? true : false );
		localStorage.setItem('approve_flag',is_approved_checked === true ? true : false );
		localStorage.setItem('isbn_flag',isbnchecked === true ? true : false );
		let isValid = true;

		if (!title) {
			isValid = false;
			setTitleError("Please enter your book title.");
		}

		if (isbnonlinechecked === true) {
			if (!Data.URL) {
				isValid = false;
				setURLError("Please enter url.");
			}
			if (typeof Data.URL !== "undefined") {
				var pattern = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
				if (pattern.test(Data.URL) === false) {
					isValid = false;
					setURLError("Please enter valid url.");
				}
			}
			if (!Date_Accessed) {
				isValid = false;
				setDateAccessedError("Please select accessed view date.");
			}
		}

		if (!Date_Published) {
			isValid = false;
			setDatePublishedError("Please select published date.");
		}
		if (!Data.publisher) {
			isValid = false;
			setPublisherError("Please enter your publisher.");
		}
		if (!Data.author) {
			isValid = false;
			setAuthorFirstNameError("Please enter your firstname.");
			setAuthorLastNameError("Please enter your lastname.");
			setAuthorTitleError("Please enter organization title")
		}
		if (!Data['publisher-place']) {
			isValid = false;
			setPublisherPlaceError("Please enter your publisher-place.");
		}

		if (Date_Accessed !== '') {
			var Data = { ...Data, "accessed": { "date-parts": [Date_Accessed] } }
		}
		if (Date_Originally_Published !== '') {
			var Data = { ...Data, "original-date": { "date-parts": [Date_Originally_Published] } }
		}

		let isErrValid = true;
		if (Data.author) {
			setAuthorFirstNameError('');
			setAuthorLastNameError('');
			setAuthorTitleError('');
			isErrValid = false;
		}
		if (title) {
			setTitleError('');
			isErrValid = false;
		}
		if (Date_Published) {
			setDatePublishedError('');
			isErrValid = false;
		}
		if (Data.publisher) {
			setPublisherError('');
			isErrValid = false;
		}
		if (Data['publisher-place']) {
			setPublisherPlaceError('');
			isErrValid = false;
		}
		if (Data.URL) {
			var pattern = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
			if (pattern.test(Data.URL) !== false) {
				setURLError('');
				isErrValid = false;
			}
		}
		if (Date_Accessed) {
			setDateAccessedError('');
			isErrValid = false;
		}
		if (title && Date_Published && Data.publisher && Data.author && Data['publisher-place']) {
			if (isbnonlinechecked === true) {
				if (Data.URL && Date_Accessed) {
					var book_data = JSON.stringify(Data)
					var refeData = { id: id, title: title, isbn: isbn, author:author,is_approved: is_approved, data: book_data }
				    dispatch(editReference(refeData))
					toast.success("Book data updated successfully...", {
						position: "top-right",
						autoClose: 1000
					});
					editsetOpen(false)
					handleClose_ref();
				}
			}
			else {
				var book_data = JSON.stringify(Data)
				var refeData = { id: id, title: title, isbn: isbn,author:author, is_approved: is_approved, data: book_data }
			    dispatch(editReference(refeData))
				toast.success("Book data updated successfully...", {
					position: "top-right",
					autoClose: 1000
				});
				editsetOpen(false)
				handleClose_ref();
			}
		}
	}
	// END :: fucntion to update book reference data

	// START :: fucntion to get data for approved,isbn,unapproved
	const getReferencedata = (userdata, index) => {
		if (isbnchecked === true) {
			return (
				<>
					{userdata.data === '' ?
						userdata.isbn == "null" || userdata.isbn == null || userdata.isbn == "undefined" || userdata.isbn == "" || userdata.isbn == "Isbn" ? "" :
							<Card className={classes.root} onClick={() => handleEditClick(userdata.id, userdata.title, userdata.isbn, userdata.data, userdata.is_approved)}
								id={userdata.id}>
								<div className={classes.details} key={index}>
									<CardContent className={classes.content} key={index}>
										<Typography component="h5" variant="h5">
											<span className="f-w-700">Isbn : </span> {userdata.isbn && userdata.isbn == null || userdata.isbn == "undefined" ? "" : userdata.isbn}
										</Typography>
									</CardContent>
								</div>
								<CardMedia
									className={classes.cover}
									image={Easy}
									title="Live from space album cover"
								/>
							</Card>
						: ""
					}
				</>
			);
		}
		else {
			return (
				<>
					{userdata.data === '' ?
						<Card className={classes.root} onClick={() => handleEditClick(userdata.id, userdata.title, userdata.isbn, userdata.data, (userdata.is_approved == 1 || userdata.is_approved != 1 ? userdata.is_approved : ""))}
							id={userdata.id}>
							<div className={classes.details} key={index}>
								<CardContent className={classes.content} key={index}>
									{userdata.isbn == "null" || userdata.isbn == null || userdata.isbn == "undefined" || userdata.isbn == "" || userdata.isbn == "Isbn" ? "" : <Typography component="h5" variant="h5"><span className="f-w-700">Isbn : </span> {userdata.isbn}</Typography>}
								</CardContent>
							</div>
							<CardMedia
								className={classes.cover}
								image={Easy}
								title="Live from space album cover"
							/>
						</Card> :
						<Card className={classes.root} onClick={() => handleEditClick(userdata.id, userdata.title, userdata.isbn, userdata.data, (userdata.is_approved == 1 || userdata.is_approved != 1 ? userdata.is_approved : ""))}
							id={userdata.id}>
							<div className={classes.details} key={index}>
								<CardContent className={classes.content} key={index}>
									{userdata.isbn == "null" || userdata.isbn == null || userdata.isbn == "undefined" || userdata.isbn == "" || userdata.isbn == "Isbn" ? "" : <Typography component="h5" variant="h5"><span className="f-w-700">Isbn : </span> {userdata.isbn}</Typography>}
									{userdata.title == "" || userdata.title == null ? "" : <Typography variant="subtitle1" color="textSecondary">
										<span className="f-w-700">Title : </span>{userdata.title}</Typography>}
									<Typography variant="subtitle1" color="textSecondary">
										{userdata && userdata.data && userdata.data == null || userdata.data == '' || userdata.data == undefined || JSON.parse(userdata.data).author == undefined || JSON.parse(userdata.data).author == "" ? "" :
											<div>
												<span className="f-w-700">Author : </span>
												{userdata.data && JSON.parse(userdata.data).author.map((authordata, index) => {
													if (authordata.literal !== "") {
														if (JSON.parse(userdata.data).author.length - 1 === index) {
															return (
																<span>{authordata.literal}</span>
															);
														}
														else {
															return (
																<span>{authordata.literal},</span>
															);
														}
													}
													else {
														if (JSON.parse(userdata.data).author.length - 1 === index) {
															return (
																<span>{authordata.given}</span>
															);
														}
														else {
															return (
																<span>{authordata.given},</span>
															);
														}
													}
												})}
											</div>}
									</Typography>
									<Typography variant="subtitle1" color="textSecondary">
										{userdata.data && userdata.data == null || userdata.data == '' || JSON.parse(userdata.data).editor == undefined || JSON.parse(userdata.data).editor == "" ? "" :
											<div>
												<span className="f-w-700">Editor : </span>
												{userdata.data && JSON.parse(userdata.data).editor.map((editordata, index) => {
													if (editordata.literal !== "") {
														if (JSON.parse(userdata.data).editor.length - 1 === index) {
															return (
																<span>{editordata.literal}</span>
															);
														}
														else {
															return (
																<span>{editordata.literal},</span>
															);
														}
													}
													else {
														if (JSON.parse(userdata.data).editor.length - 1 === index) {
															return (
																<span>{editordata.given}</span>
															);
														}
														else {
															return (
																<span>{editordata.given},</span>
															);
														}
													}
												})}
											</div>}
									</Typography>
									<Typography variant="subtitle1" color="textSecondary">
										{userdata.data && JSON.parse(userdata.data) == null || userdata.data == '' || JSON.parse(userdata.data).translator == undefined || JSON.parse(userdata.data).translator == "" ? "" :
											<div>
												<span className="f-w-700">Translator : </span>
												{userdata.data && JSON.parse(userdata.data).translator.map((translatordata, index) => {
													if (translatordata.literal !== "") {
														if (JSON.parse(userdata.data).translator.length - 1 === index) {
															return (
																<span>{translatordata.literal}</span>
															);
														}
														else {
															return (
																<span>{translatordata.literal},</span>
															);
														}
													}
													else {
														if (JSON.parse(userdata.data).translator.length - 1 === index) {
															return (
																<span>{translatordata.given}</span>
															);
														}
														else {
															return (
																<span>{translatordata.given},</span>
															);
														}
													}
												})}
											</div>}
									</Typography>
									<Typography variant="subtitle1" color="textSecondary">
										{
											userdata.data && JSON.parse(userdata.data)['publisher-place'] == undefined ? "" : <Typography variant="subtitle1" color="textSecondary">
												<span className="f-w-700">Publisher-Place : </span>{userdata.data && JSON.parse(userdata.data)['publisher-place'] == undefined ? "" : JSON.parse(userdata.data)['publisher-place']}
											</Typography>
										}
									</Typography>
								</CardContent>
							</div>
							<CardMedia
								className={classes.cover}
								image={Easy}
								title="Live from space album cover"
							/>
						</Card>
					}
				</>
			);
		}
	};
	// END :: fucntion to get data for approved,isbn,unapproved

	// START :: fucntion to add book reference data
	const handleSubmit = (e) => {
		e.preventDefault();
		var title = formData.title ? formData.title : "";
		var is_approved = is_approvedchecked == true ? 1 : 0;
		var isbn = formData.isbn ? formData.isbn.replace(/\b(\w)/g, s => s.toUpperCase()) : '';
		var author = inputList[0].given !== '' ? inputList[0].given : '';
		var Date_Published = date_published != null ? moment(date_published).format("YYYY-MM-DD").split("-") : '';
		var Date_Accessed = date_accessed_viewed != null ? moment(date_accessed_viewed).format("YYYY-MM-DD").split("-") : '';
		var Date_Originally_Published = date_originally_published != null ? moment(date_originally_published).format("YYYY-MM-DD").split("-") : '';
		var Data = {
			"id": "item-1",
			"type": "book",
			"author": inputList[0].given !== '' && inputList[0].family !== '' || inputList[0].literal !== '' ? inputList : '',
			"editor": editorList[0].given !== '' || editorList[0].literal !== '' ? editorList : '',
			"translator": transList[0].given !== '' || transList[0].literal !== '' ? transList : '',
			"title": formData.title,
			"issued": { "date-parts": [Date_Published] },
			"URL": formData.URL ? formData.URL : '',
			"annote": formData.annote ? formData.annote : '',
			"volume": formData.volume ? formData.volume : '',
			"ISBN": formData.isbn ? formData.isbn : '',
			"publisher-place": formData.Publisher_Place ? formData.Publisher_Place : '',
			"archive-place": formData.Publisher_Place ? formData.Publisher_Place : '',
			"publisher": formData.publisher ? formData.publisher : '',
			"edition": formData.edition ? formData.edition : '',
			"locator": formData.Page ? formData.Page : '',
			"page": formData.page ? formData.page : '',
		}
		localStorage.setItem('unapprove_flag',disapprovedchecked === true ? true : false );
		localStorage.setItem('approve_flag',is_approved_checked === true ? true : false );
		localStorage.setItem('isbn_flag',isbnchecked === true ? true : false );
		let isValid = true;
		if (!title) {
			isValid = false;
			setTitleError("Please enter your book title.");
		}
		if (check_online === true) {
			if (!Date_Accessed) {
				isValid = false;
				setDateAccessedError("Please select accessed view date.");
			}
			if (!Data.URL) {
				isValid = false;
				setURLError("Please enter url.");
			}

			if (typeof Data.URL !== "undefined") {
				var pattern = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
				if (!pattern.test(Data.URL)) {
					isValid = false;
					setURLError("Please enter valid url.");
				}
			}
		}
		if (!Date_Published) {
			isValid = false;
			setDatePublishedError("Please select published date.");
		}
		if (!Data.publisher) {
			isValid = false;
			setPublisherError("Please enter your publisher.");
		}
		if (!Data.author) {
			isValid = false;
			setAuthorFirstNameError("Please enter your firstname.");
			setAuthorLastNameError("Please enter your lastname.");
			setAuthorTitleError("Please enter organization title")
		}
		if (Data.author.length && Data.author.length > 0) {
			isValid = false;
			setAuthorFirstNameError("Please enter your firstname.");
			setAuthorLastNameError("Please enter your lastname.");
		}
		if (!Data['publisher-place']) {
			isValid = false;
			setPublisherPlaceError("Please enter your publisher-place.");
		}
		if (Date_Accessed !== '') {
			var Data = { ...Data, "accessed": { "date-parts": [Date_Accessed] } }
		}
		if (Date_Originally_Published !== '') {
			var Data = { ...Data, "original-date": { "date-parts": [Date_Originally_Published] } }
		}

		let isErrValid = true;

		if (Data.author.length && Data.author.length > 0) {
			setAuthorFirstNameError('');
			setAuthorLastNameError('');
			setAuthorTitleError('');
			isErrValid = false;
		}
		if (Data.author) {
			setAuthorFirstNameError('');
			setAuthorLastNameError('');
			setAuthorTitleError('');
			isErrValid = false;
		}
		if (title) {
			setTitleError('');
			isErrValid = false;
		}
		if (Date_Published) {
			setDatePublishedError('');
			isErrValid = false;
		}
		if (Data.publisher) {
			setPublisherError('');
			isErrValid = false;
		}
		if (Data['publisher-place']) {
			setPublisherPlaceError('');
			isErrValid = false;
		}
		if (Data.URL) {
			var pattern = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
			if (pattern.test(Data.URL) == true) {
				setURLError('');
				isErrValid = false;
			}
		}
		if (Date_Accessed) {
			setDateAccessedError('');
			isErrValid = false;
		}
		if (title && Date_Published && Data.publisher && Data.author && Data['publisher-place']) {
			if (check_online === true) {
				if (Data.URL !== "" && Date_Accessed !== "") {
					var book_data = JSON.stringify(Data)
					var refeData = { title: title, isbn: isbn,author:author, is_approved: is_approved, data:  book_data }
					dispatch(createReference(refeData))
					toast.success("Book data added successfully...", {
						position: "top-right",
						autoClose: 1000
					});
					setOpen(false)
					handleClose_ref();
				}
			}
			else {
				var book_data = JSON.stringify(Data)
				var refeData = { title: title, isbn: isbn, author:author,is_approved: is_approved, data: book_data }
				dispatch(createReference(refeData))
				toast.success("Book data added successfully...", {
					position: "top-right",
					autoClose: 1000
				});
				setOpen(false)
				handleClose_ref();
			}
		}
	}
	// END :: fucntion to add book reference data

	// START :: Accordian Event By default Open for Edit
	const [expanded, setExpanded] = React.useState('panel1');
	const handleAccordianChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	// END :: Accordian Event By default Open for Edit

	return (
		<>
			<div>
				{props.isLoading ? <FullPageLoader /> : ""}
				<Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" >
					<DialogTitle id="responsive-dialog-title title_tag" className="header-bg" >{"Book"}<CloseIcon style={{ float: "right", cursor: "pointer" }} onClick={handleClose} ></CloseIcon></DialogTitle>
					<DialogContent>
						<DialogContentText>
							<form>
								<div style={{ flexDirection: "column" }}>
									<Card fullWidth variant="outlined">
										<CardHeader title="Contributor" className="header-bg" />
										<CardContent style={{ width: '100%' }}>
											<FormControl component="fieldset" fullWidth="true" variant="standard" color="info">
												<FormLabel component="legend">Book Author</FormLabel>
												<div className="col-md-12" >
													{inputList.map((e, i) => (
														<div className="mb-0">
															{e.hide ?
																<Row className="m_b_10" >
																	<div className="col-md-5">
																		<TextField id="given" fullwidth label="First Name(s)" margin="normal" name="given" value={e.given} onChange={e => handleInputChange(e, i)} />
																		<div className="text-danger">{authorFirstnameError}</div>
																	</div>
																	<div className="col-md-5">
																		<TextField id="family" fullwidth label="Last Name(s)" margin="normal" name="family" value={e.family} onChange={e => handleInputChange(e, i)} />
																		<div className="text-danger">{authorLastnameError}</div>
																	</div>
																	<div className="col-md-2">
																		<AccountCircleIcon name="hide" className="book_icon" onClick={e => handleInputChange(e, i)} />
																		{inputList.length !== 1 && <RemoveIcon className="close_icon" style={{ fill: "red" }} onClick={() => handleRemoveClick(i)} />}
																	</div>
																</Row> :
																<Row className="m_b_10" >
																	<div className="col-md-10">
																		<TextField id={'asd' + i} fullWidth margin="normal" name="literal" label="Company or Organization Name" value={e.literal} onChange={e => handleInputChange(e, i)} />
																		<div className="text-danger">{authorTitleError}</div>
																	</div>
																	<div className="col-md-2">
																		<HouseIcon name="hide" className="book_icon" onClick={e => handleInputChange(e, i)} />
																		{inputList.length !== 1 && <RemoveIcon style={{ fill: "red" }} className="book_icon" onClick={() => handleRemoveClick(i)} />}
																	</div>
																</Row>
															}
															<div className="">
																{inputList.length - 1 === i && <Button variant="contained" color="primary" className="book_btn" onClick={handleAddClick} endIcon={<AddCircleOutlineIcon />}>Add Author</Button>}
																{inputList.length - 1 === i && <Button variant="contained" color="primary" className={editor_show_b} onClick={() => editorDisplay()} endIcon={<AddCircleOutlineIcon />}>Add Editior</Button>}
																{inputList.length - 1 === i && <Button variant="contained" color="primary" className={trans_show_b} onClick={() => transDisplay()} endIcon={<AddCircleOutlineIcon />}>Add Translator</Button>}
															</div>
														</div>
													))}
												</div>
											</FormControl>
											<div className="col-md-12" >
												{editorList.length > 0 && editor_show_b.indexOf('d-none') > -1 && <FormLabel component="legend" className="mt-4">Book Editior</FormLabel>}
												{editorList.map((e, i) => (
													<div className={editor_show}>
														{e.hide ?
															<Row className="mb-0" >
																<div className="col-md-5">
																	{
																		editor_show === 'd-block' ? <TextField id="given" label="First Name(s)" margin="normal" name="given" value={e.given} onChange={e => handleEditorChange(e, i)} /> : <TextField id="given" label="First Name(s)" margin="normal" name="given" value={e.given} onChange={e => handleEditorChange(e, i)} />
																	}
																																											<div className="text-danger">{authorFirstnameError}</div>

																</div>
																<div className="col-md-5">
																	{
																		editor_show === 'd-block' ? <TextField id="family" label="Last Name(s)" margin="normal" name="family" value={e.family} onChange={e => handleEditorChange(e, i)} /> : <TextField id="family" label="Last Name(s)" margin="normal" name="family" value={e.family} onChange={e => handleEditorChange(e, i)} />
																	}
																	<div className="text-danger">{authorLastnameError}</div>
																</div>
																<div className="col-md-2">
																	<AccountCircleIcon className="book_icon" name="hide" onClick={e => handleEditorChange(e, i)} />
																	<RemoveIcon className="book_icon" style={{ fill: "red" }} onClick={() => handleeditorRemoveClick(i)} />
																</div>
															</Row> :
															<Row className="m_b_10" >
																<div className="col-md-10">
																	<TextField id={'asd' + i} fullWidth margin="normal" name="literal" label="Company or Organization Name" value={e.literal} onChange={e => handleEditorChange(e, i)} />
																	<div className="text-danger">{authorTitleError}</div>
																</div>
																<div className="col-md-2">
																	<HouseIcon name="hide" className="book_icon" onClick={e => handleEditorChange(e, i)} />
																	<RemoveIcon style={{ fill: "red" }} className="book_icon" onClick={() => handleeditorRemoveClick(i)} />
																</div>
															</Row>
														}
														{editorList.length - 1 === i && <div className="col-md-12">
															<Button variant="contained" color="primary" onClick={handleeditorAddClick} endIcon={<AddCircleOutlineIcon>Add Editior</AddCircleOutlineIcon>}>Add Editior</Button>
														</div>}
													</div>
												))}
											</div>
											<div className="col-md-12" >
												{transList.length > 0 && trans_show_b.indexOf('d-none') > -1 && <FormLabel component="legend" className="mt-4">Book Translator</FormLabel>}
												{transList.map((e, i) => (
													<div className={trans_show}>
														{e.hide ?
															<Row className="mb-0">
																<div className="col-md-5">
																	{
																		trans_show === 'd-block' ? <TextField id="given" label="First Name(s)" margin="normal" name="given" value={e.given} onChange={e => handletransChange(e, i)} /> : <TextField id="given" label="First Name(s)" margin="normal" name="given" value={e.given} onChange={e => handletransChange(e, i)} />
																	}
																<div className="text-danger">{authorFirstnameError}</div>
																</div>
																<div className="col-md-5">
																	{
																		trans_show === 'd-block' ? <TextField id="family" label="Last Name(s)" margin="normal" name="family" value={e.family} onChange={e => handletransChange(e, i)} /> : <TextField id="family" label="Last Name(s)" margin="normal" name="family" value={e.family} onChange={e => handletransChange(e, i)} />
																	}
																	<div className="text-danger">{authorLastnameError}</div>
																</div>
																<div className="col-md-2">
																	<AccountCircleIcon className="book_icon" name="hide" onClick={e => handletransChange(e, i)} />
																	<RemoveIcon style={{ fill: "red" }} className="book_icon" onClick={() => handletransRemoveClick(i)} />
																</div>
															</Row> :
															<Row className="m_b_10" >
																<div className="col-md-10">
																	<TextField id={i} fullWidth margin="normal" name="literal" label="Company or Organization Name" value={e.literal} onChange={e => handletransChange(e, i)} />
																	<div className="text-danger">{authorTitleError}</div>
																</div>
																<div className="col-md-2">
																	<HouseIcon className="book_icon" name="hide" onClick={e => handletransChange(e, i)} />
																	<RemoveIcon style={{ fill: "red" }} className="book_icon" onClick={() => handletransRemoveClick(i)} />
																</div>
															</Row>
														}
														<div className="">
															{transList.length - 1 === i && <Button variant="contained" color="primary" onClick={handletransClick} endIcon={<AddCircleOutlineIcon />}>Add Translator</Button>}
														</div>
													</div>
												))}
											</div>
										</CardContent>
									</Card>
									<Card fullWidth variant="outlined" className="mt-4">
										<CardHeader title="Book" className="header-bg" />
										<CardContent style={{ width: '100%' }}>
											<Typography>
												<div className="col-md-12">
													<TextField label="Title of Book" margin="normal" name="title" isRequired="true" onChange={(e) => handleChange(e)} fullWidth > </TextField>
													<div className="text-danger">{titleError}</div>
													<Row className="m_b_10">
														<div className="col-md-10 datepicker_tag">
															<DatePicker popperProps={{ positionFixed: true }} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" popperModifiers={popperModifiers} name="Date_Published" className="form-control text-capitalize date_published_error" label="Date Published" placeholderText="Date Published" selected={date_published} dateFormat="yyyy-MM-dd" onChange={(date) => setDate_p(date)} />
														</div>
														<div className="col-md-2">
															<p className="btn btn-info m-b-10" style={{ marginTop: 16 }} onClick={() => DateGet_Published()} >Today</p>
														</div>
													</Row>
													<div className="text-danger">{datepublishedError}</div>
													<Row className="m_b_10" >
														<div className="col-md-10 datepicker_tag">
															<DatePicker popperProps={{ positionFixed: true }} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" popperModifiers={popperModifiers} name="Date_Originally_Published" className="form-control text-capitalize date_published" label="Date Originally Published" placeholderText="Date Originally Published" selected={date_originally_published} label="Date Originally Published" dateFormat="yyyy-MM-dd" onChange={(date) => setDate_o(date)} />
														</div>
														<div className="col-md-2">
															<p className="btn btn-info m-b-10" style={{ marginTop: 16 }} onClick={() => DateGet_Originally_Published()} >Today</p>
														</div>
													</Row>
													<TextField label="Edition" margin="normal" name="edition" onChange={(e) => handleChange(e)} fullWidth ></TextField>
													<TextField label="Volume No" margin="normal" name="volume" onChange={(e) => handleChange(e)} fullWidth></TextField>
													<TextField label="Publisher" margin="normal" name="publisher" onChange={(e) => handleChange(e)} fullWidth > </TextField>
													<div className="text-danger">{publisherError}</div>
													<TextField label="Publisher Place" margin="normal" name="Publisher_Place" onChange={(e) => handleChange(e)} fullWidth > </TextField>
													<div className="text-danger">{publisherplaceError}</div>
													<TextField label="Page Range" margin="normal" name="page" onChange={(e) => handleChange(e)} fullWidth > </TextField>
													<TextField label="Isbn" margin="normal" name="isbn" onChange={(e) => handleChange(e)} fullWidth > </TextField>
													<div className="text-danger">{isbnError}</div>

													<br />
													<div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
														<FormControlLabel
															control={<Switch onChange={handleCheckBookonline} label="switch" name="checkedB" color="primary" />}
															label="Is Book Online"
														/>
													</div>
													{
														check_online ?
															<>

																<TextField label="Url" margin="normal" name="URL" onChange={(e) => handleChange(e)} fullWidth ></TextField>
																<div className="text-danger">{urlError}</div>
																<Row className="m_b_10" >
																	<div className="col-md-10 datepicker_tag">
																		<DatePicker popperProps={{ positionFixed: true }} popperPlacement="top"
																			peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" popperModifiers={popperModifiers} name="Date_Accessed_viewed" className="form-control text-capitalize date_published_error" label="Date Originally Published" placeholderText="Date Originally Published" selected={date_accessed_viewed} label="Data Accessed View" dateFormat="yyyy-MM-dd" onChange={(date) => setDate_a(date)} />
																	</div>
																	<div className="col-md-2">
																		<p className="btn btn-info m-b-10" style={{ marginTop: 16 }} onClick={() => DateGet_Accessed_Viewed()} >Today</p>
																	</div>
																</Row>
																<div className="text-danger">{dateaccessedError}</div>
															</> : ''
													}
												</div>
											</Typography>
										</CardContent>
									</Card>
									<Card fullWidth variant="outlined" className="mt-4">
										<CardHeader title="Annotation" className="header-bg" />
										<CardContent style={{ width: '100%' }}>
											<Typography>
												<div className="container">
													<div className="row">
														<div className="col-md-12">
															<textarea name="annote" className="form-control " style={{ marginTop: 2 }} placeholder="Annotation" onChange={(e) => handleChange(e)}></textarea>
														</div>
													</div>
												</div>
											</Typography>
										</CardContent>
									</Card>
								</div>
							</form>
						</DialogContentText>
					</DialogContent>
					{/* <hr /> */}
					<DialogActions className="modal-footer">
						{allow_to_approve_reference == "true" ?
							<FormControlLabel className="approve_check" name="is_approved" onClick={(e) => { handleAddApprovedchange(e) }} checked={is_approvedchecked} control={<Checkbox name="checkedC" />} label="Approved" /> : ""}
						<Button variant="contained" autoFocus color="primary" className="btn_close_cancel btn_close_width" onClick={handleClose}>Cancel</Button>
						<Button variant="contained" autoFocus color="primary" className="book_btn all_btn_width " onClick={handleSubmit}>Save</Button>
					</DialogActions>
				</Dialog>
				{/* ================================================= Edit modal ========================================================================= */}
				<Dialog fullScreen={fullScreen} open={editopen} onClose={handleEditClose} aria-labelledby="responsive-dialog-title" >
					<DialogTitle id="responsive-dialog-title title_tag" className="header-bg" >{"Edit Book"}<CloseIcon style={{ float: "right", cursor: "pointer" }} onClick={handleEditClose} ></CloseIcon></DialogTitle>
					<DialogContent>
						<DialogContentText>
							<form>
								<div style={{ flexDirection: "column" }}>
									<Card fullWidth variant="outlined">
										<CardHeader title="Contributor" className="header-bg" />
										<CardContent style={{ width: '100%' }}>
											<FormControl component="fieldset" fullWidth="true" variant="standard" color="info">
												<FormLabel component="legend">Book Author</FormLabel>
												<div className="col-md-12" >
													{inputList.map((e, i) => (
														<div className="mb-0">
															{e.hide ?
																<Row className="m_b_10" >
																	<div className="col-md-4">
																		<TextField id="given" fullwidth label="First Name(s)" margin="normal" name="given" value={e.given} onChange={e => handleInputChange(e, i)} />
																		<div className="text-danger">{authorFirstnameError}</div>
																	</div>
																	<div className="col-md-4">
																		<TextField id="family" fullwidth label="Last Name(s)" margin="normal" name="family" value={e.family} onChange={e => handleInputChange(e, i)} />
																		<div className="text-danger">{authorLastnameError}</div>
																	</div>
																	<div className="col-md-4">
																		<AccountCircleIcon name="hide" className="book_icon" onClick={e => handleInputChange(e, i)} />
																		{inputList.length !== 1 && <RemoveIcon className="close_icon" onClick={() => handleRemoveClick(i)} />}
																	</div>
																</Row> :
																<Row className="m_b_10" >
																	<div className="col-md-8">
																		<TextField id={'as' + i} fullWidth margin="normal" label="Company or Organization Name" name="literal" value={e.literal} onChange={e => handleInputChange(e, i)} />
																		<div className="text-danger">{authorTitleError}</div>
																	</div>
																	<div className="col-md-4">
																		<HouseIcon name="hide" className="book_icon" onClick={e => handleInputChange(e, i)} />
																		{inputList.length !== 1 && <RemoveIcon style={{ fill: "red" }} className="book_icon" onClick={() => handleRemoveClick(i)} />}
																	</div>
																</Row>
															}
														</div>
													))}
													<div className="col-md-12">
														<Button variant="contained" color="primary" className="book_btn" onClick={handleAddClick} endIcon={<AddCircleOutlineIcon />}>Add Author</Button>
														<Button variant="contained" color="primary" className={editor_show_b} onClick={() => editorDisplay()} endIcon={<AddCircleOutlineIcon />}>Add Editior</Button>
														<Button variant="contained" color="primary" className={trans_show_b} onClick={() => transDisplay()} endIcon={<AddCircleOutlineIcon />}>Add Translator</Button>
													</div>
												</div>
												<div className="col-md-12 mt-4" >
													{editorList.length > 0 && editor_show_b.indexOf('d-none') > -1 && <FormLabel component="legend">Book Editior</FormLabel>}
													{editorList.map((e, i) => (
														<div className={editor_show}>
															{e.hide ?
																<Row className="mb-0" >
																	<div className="col-md-4">
																		{
																			editor_show === 'd-block' ? <TextField id="given" label="First Name(s)" margin="normal" name="given" value={e.given} onChange={e => handleEditorChange(e, i)} required /> : <TextField id="given" label="First Name(s)" error margin="normal" name="given" value={e.given} onChange={e => handleEditorChange(e, i)} />
																		}
																		<div className="text-danger">{authorFirstnameError}</div>
																	</div>
																	<div className="col-md-4">
																		{
																			editor_show === 'd-block' ? <TextField id="family" label="Last Name(s)" margin="normal" name="family" value={e.family} onChange={e => handleEditorChange(e, i)} required /> : <TextField id="family" label="Last Name(s)" error margin="normal" name="family" value={e.family} onChange={e => handleEditorChange(e, i)} />
																		}
																		<div className="text-danger">{authorLastnameError}</div>
																	</div>
																	<div className="col-md-4">
																		<AccountCircleIcon className="book_icon" name="hide" onClick={e => handleEditorChange(e, i)} />
																		<RemoveIcon className="book_icon" style={{ fill: "red" }} onClick={() => handleediteditorRemoveClick(i)} />
																	</div>
																</Row> :
																<Row className="m_b_10" >
																	<div className="col-md-8">
																		<TextField id={'asdf' + i} fullWidth margin="normal" name="literal" label="Company or Organization Name" value={e.literal} onChange={e => handleEditorChange(e, i)} />
																		<div className="text-danger">{authorTitleError}</div>
																	</div>
																	<div className="col-md-4">
																		<HouseIcon name="hide" className="book_icon" onClick={e => handleEditorChange(e, i)} />
																		<RemoveIcon style={{ fill: "red" }} className="book_icon" onClick={() => handleediteditorRemoveClick(i)} />
																	</div>
																</Row>
															}
														</div>
													))}
													{editorList.length > 0 && editor_show_b.indexOf('d-none') > -1 && <div className="col-md-12" >
														<Button variant="contained" color="primary" onClick={handleeditorAddClick} endIcon={<AddCircleOutlineIcon />}>Add Editior</Button>
													</div>}
												</div>
												<div className="col-md-12 mt-4" >
													{transList.length > 0 && trans_show_b.indexOf('d-none') > -1 && <FormLabel component="legend">Book Translator</FormLabel>}
													{transList.map((e, i) => (
														<div className={trans_show}>
															{e.hide ?
																<Row className="mb-0">
																	<div className="col-md-4">
																		{
																			trans_show === 'd-block' ? <TextField id="given" label="First Name(s)" margin="normal" name="given" value={e.given} onChange={e => handletransChange(e, i)} required /> : <TextField id="given" label="First Name(s)" error margin="normal" name="given" value={e.given} onChange={e => handletransChange(e, i)} />
																		}
																			<div className="text-danger">{authorFirstnameError}</div>
																	</div>
																	<div className="col-md-4">
																		{
																			trans_show === 'd-block' ? <TextField id="family" label="Last Name(s)" margin="normal" name="family" value={e.family} onChange={e => handletransChange(e, i)} required /> : <TextField id="family" label="Last Name(s)" error margin="normal" name="family" value={e.family} onChange={e => handletransChange(e, i)} />
																		}
																		<div className="text-danger">{authorLastnameError}</div>
																	</div>
																	<div className="col-md-4">
																		<AccountCircleIcon className="book_icon" name="hide" onClick={e => handletransChange(e, i)} />
																		<RemoveIcon className="book_icon" style={{ fill: "red" }} onClick={() => handleedittransRemoveClick(i)} />
																	</div>
																</Row> :
																<Row className="m_b_10" >
																	<div className="col-md-8">
																		<TextField id={i} margin="normal" name="literal" placeholder="Company or Organization Name" value={e.literal} onChange={e => handletransChange(e, i)} />
																		<div className="text-danger">{authorTitleError}</div>
																	</div>
																	<div className="col-md-4">
																		<HouseIcon name="hide" className="book_icon" onClick={e => handletransChange(e, i)} />
																		<RemoveIcon style={{ fill: "red" }} className="book_icon" onClick={() => handleedittransRemoveClick(i)} />
																	</div>
																</Row>
															}
														</div>
													))}
													{transList.length > 0 && trans_show_b.indexOf('d-none') > -1 && <div className="col-md-12" >
														<Button variant="contained" color="primary" onClick={handletransClick} endIcon={<AddCircleOutlineIcon />}>Add Translator</Button>
													</div>}
												</div>
											</FormControl>
										</CardContent>
									</Card>
									<Card fullWidth variant="outlined" className="mt-4">
										<CardHeader title="Book" className="header-bg" />
										<CardContent style={{ width: '100%' }}>
											<FormControl component="fieldset" fullWidth="true" variant="standard" color="info">
												<FormLabel component="legend">Book</FormLabel>
												<div className="col-md-12">
													<TextField label="Title of Book" margin="normal" name="title" value={book_title_desc} isRequired="true" required={true} onChange={e => settitle(e.target.value)} fullWidth></TextField>
													<div className="text-danger">{titleError}</div>
													<Row className="m_b_10">
														<div className="col-md-10 datepicker_tag">
															<DatePicker popperProps={{ positionFixed: true }} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" popperModifiers={popperModifiers} name="Date_Published" className="form-control text-capitalize date_published_error" label="Date Published" placeholderText="Date Published" selected={date_published} dateFormat="yyyy-MM-dd" onChange={(date) => setDate_p(date)} />
														</div>
														<div className="col-md-2">
															<p className="btn btn-info m-b-10" style={{ marginTop: 16 }} onClick={() => DateGet_Published()} >Today</p>
														</div>
													</Row>
													<div className="text-danger">{datepublishedError}</div>
													<Row className="m_b_10">
														<div className="col-md-10 datepicker_tag">
															<DatePicker popperProps={{ positionFixed: true }} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" popperModifiers={popperModifiers} name="Date_Originally_Published" className="form-control text-capitalize date_published" label="Date Originally Published" placeholderText="Date Originally Published" selected={date_originally_published} label="Date Originally Published" dateFormat="yyyy-MM-dd" onChange={(date) => setDate_o(date)} />
														</div>
														<div className="col-md-2">
															<p className="btn btn-info m-b-10" style={{ marginTop: 16 }} onClick={() => DateGet_Originally_Published()} >Today</p>
														</div>
													</Row>
													<TextField label="Edition" margin="normal" name="edition" value={formData.edition} onChange={(e) => handleChange(e)} fullWidth > </TextField>
													<TextField label="Volume No" margin="normal" name="volume" value={formData.volume} onChange={(e) => handleChange(e)} fullWidth > </TextField>
													<TextField label="Publisher" margin="normal" name="publisher" value={formData.publisher} onChange={(e) => handleChange(e)} fullWidth > </TextField>
													<div className="text-danger">{publisherError}</div>
													<TextField label="Publisher Place" margin="normal" name="publisher-place" value={formData['publisher-place']} onChange={(e) => handleChange(e)} fullWidth > </TextField>
													<div className="text-danger">{publisherplaceError}</div>
													<TextField label="Page Range" margin="normal" name="page" value={formData.page} onChange={(e) => handleChange(e)} fullWidth > </TextField>
													<TextField label="Isbn" margin="normal" name="isbn" value={isbn_number} onChange={e => setisbn(e.target.value)} fullWidth > </TextField>
													<div className="text-danger">{isbnError}</div>
													<br />
													<div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
														<FormControlLabel control={<Switch onChange={handleisbnonlinechange} checked={isbnonlinechecked ? 'checked' : null} label="switch" name="checkedB" color="primary" />} label="Is Book Online" />
													</div>
													{
														isbnonlinechecked ?
															<>
																<TextField label="Url" margin="normal" name="URL" value={formData.URL} onChange={(e) => handleChange(e)} fullWidth > </TextField>
																<div className="text-danger">{urlError}</div>
																<Row className="m_b_10">
																	<div className="col-md-10 datepicker_tag">
																		<DatePicker popperProps={{ positionFixed: true }} popperPlacement="top" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" popperModifiers={popperModifiers} name="Date_Accessed_viewed" className="form-control text-capitalize date_published_error" label="Date Originally Published" placeholderText="Date Originally Published" selected={date_accessed_viewed} label="Data Accessed View" dateFormat="yyyy-MM-dd" inputVariant="outlined" onChange={(date) => setDate_a(date)} />
																	</div>
																	<div className="col-md-2">
																		<p className="btn btn-info m-b-10" style={{ marginTop: 16 }} onClick={() => DateGet_Accessed_Viewed()} >Today</p>
																	</div>
																</Row>
																<div className="text-danger">{dateaccessedError}</div>
															</> : ''
													}
												</div>
											</FormControl>
										</CardContent>
									</Card>
									<Card fullWidth variant="outlined" className="mt-4">
										<CardHeader title="Annotation" className="header-bg" />
										<CardContent style={{ width: '100%' }}>
											<FormControl component="fieldset" fullWidth="true" variant="standard" color="info">
												<div className="container">
													<div className="row">
														<div className="col-md-12">
															<textarea name="annote" value={formData.annote} style={{ marginTop: 2 }} className="form-control " placeholder="Annotation" onChange={(e) => handleChange(e)}></textarea>
														</div>
													</div>
												</div>
											</FormControl>
										</CardContent>
									</Card>
								</div>
							</form>
						</DialogContentText>
					</DialogContent>
					{/* <hr /> */}
					<DialogActions className="modal-footer">
						<Button variant="outlined" className="delete_btn" onClick={() => handleRefDeleteClick(ref_id,isbnchecked,is_approved_checked,disapprovedchecked)} >Delete</Button>
						{allow_to_approve_reference == "true" ?
							<FormControlLabel className="approve_check" name="is_approved" control={<Checkbox name="checkedC" disabled={editAdminApproveDisable} onClick={(e) => { handleEditApprovedchange(e) }} checked={edit_approved_checked} />} label="Approved" /> : <FormControlLabel className="approve_check" name="is_approved" control={<Checkbox name="checkedC" onClick={(e) => { handleEditApprovedchange(e) }} checked={edit_approved_checked} />} label="Approved" />}
						<Button variant="contained" autoFocus color="primary" className="btn_close_cancel" onClick={handleEditClose}>Cancel</Button>
						{allow_to_approve_reference === "true" ?
						<Button variant="contained" disabled={editAdminButtonApproveDisable} autoFocus color="primary" className="book_btn all_btn_width width_btn" onClick={handleEditSubmit}>Update</Button> : 
						<Button variant="contained" autoFocus color="primary" className="book_btn all_btn_width width_btn" onClick={handleEditSubmit}>Update</Button>}
					</DialogActions>
				</Dialog>
				{/* ==============================================Delete Modal ============================================================== */}
				<Dialog
					open={deleteopen}
					onClose={handleDeleteClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="responsive-dialog-title title_tag">{"Delete Reference"}<CloseIcon style={{ float: "right" }} onClick={handleDeleteClose} ></CloseIcon></DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to delete selected reference?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" autoFocus color="primary" className="book_btn" onClick={handleDeleteClose}>Cancel</Button>
						<Button variant="contained" autoFocus color="primary" className="book_btn" onClick={handleDeleteSubmit}>Delete</Button>
					</DialogActions>
				</Dialog>
				<ToastContainer />
				<div className="search_div">
					<div className="header-top">
						<form onSubmit={(e) => searchsubmit(e)}>
							<div className="search-input-div">
								<SearchBar className="header-search-bar" style={{ padding: '0px', display: 'flex', width: '100%', height: '37px', position: 'relative' }}
									className="search_div"
									onChange={(e) => handleSearchbook(e)}
									value={search_value}
									placeholder="Search Book"
									onCancelSearch={(e) => handleCloseSearch(e)}
									name="reference-search" id="reference_search"
								/>
							</div>
						</form>
						<div>
							<Button variant="contained" className={classes.addBookBtn} onClick={handleClickOpen} endIcon={<AddIcon />}>Book</Button>
						</div>
					</div>
					<div className="checkbox-group">
						<FormControlLabel className={classes.isbn} checked={isbnchecked} control={<Checkbox name="checkedC" onClick={(e) => { handleCheckchange(e) }} disabled={checkIsbnDisable} />} label="Isbn Only"/><span className="comman_count">{"("+props.isbn_count+")"}</span>&nbsp;&nbsp;
						<FormControlLabel className={classes.isbn} checked={is_approved_checked} control={<Checkbox name="checkedC" onClick={(e) => { handleApprovedchange(e) }} disabled={checkApproveDisable} />} label="Approved"/><span className="comman_count">{"("+props.approved_count+")"}</span>&nbsp;&nbsp;
						<FormControlLabel className={classes.isbn} checked={disapprovedchecked} control={<Checkbox name="checkedC" onClick={(e) => { handleDisapprovedchange(e) }} disabled={checkUnApproveDisable} />} label="Unapproved"/><span className="comman_count">{"("+props.unapproved_count+")"}</span>
					</div>
				</div>
				{props.searcherrormsg === true ? <div className="list_show_no_record" >No Record Found</div> : ""}
				<InfiniteScroll
					initialScrollY={false}
					dataLength={props && props.books && props.books.length}
					next={fetchMoreData}
					hasMore={props.books ? props.total > props.books.length : false}
					scrollableTarget="scrollableDiv"
                    style={{"overflow-x":"hidden"}}
					loader={loadingState ? <b>{loadingMessage}</b> : <h4>{loadingMessage}</h4>}
					endMessage={
						<p style={{ textAlign: "center" }}>
							<b>{loadingMessage}</b>
						</p>
					}
				>
					<div className="list_show list_reference"  id="scrollableDiv">
						{users && users.books && users.books.map((userdata, index) => {
							if (isbnchecked === true) {
								return (
									getReferencedata(userdata, index)
								);
							}
							else if (is_approved_checked === true) {
								return userdata.is_approved == "1" ? (
									getReferencedata(userdata, index))
									: "";

							}
							else if (disapprovedchecked === true) {
								return userdata.is_approved == "0" ? (
									userdata.data === '' ?
										<Card className={classes.root} onClick={() => handleEditClick(userdata.id, userdata.title, userdata.isbn, userdata.data, (userdata.is_approved == 1 || userdata.is_approved != 1 ? userdata.is_approved : ""))}
											id={userdata.id}>
											<div className={classes.details} key={index}>
												<CardContent className={classes.content} key={index}>
													{userdata.isbn == "null" || userdata.isbn == null || userdata.isbn == "undefined" || userdata.isbn == "" || userdata.isbn == "Isbn" ? "" : <Typography component="h5" variant="h5"><span className="f-w-700">Isbn : </span> {userdata.isbn}</Typography>}
												</CardContent>
											</div>
											<CardMedia
												className={classes.cover}
												image={Easy}
												title="Live from space album cover"
											/>
										</Card> :
										<Card className={classes.root} onClick={() => handleEditClick(userdata.id, userdata.title, userdata.isbn, userdata.data, userdata.is_approved)}
											id={userdata.id}>
											<div className={classes.details} key={index}>
												<CardContent className={classes.content} key={index}>
													{userdata.isbn == "null" || userdata.isbn == null || userdata.isbn == "undefined" || userdata.isbn == "" || userdata.isbn == "Isbn" ? "" : <Typography component="h5" variant="h5"><span className="f-w-700">Isbn : </span>{userdata.isbn}</Typography>}
													{userdata.title == "" || userdata.title == null ? "" : <Typography variant="subtitle1" color="textSecondary">
														<span className="f-w-700">Title : </span>{userdata.title}</Typography>}
													<Typography variant="subtitle1" color="textSecondary">
														{userdata.data && JSON.parse(userdata.data) == null || userdata.data == '' || JSON.parse(userdata.data).author == undefined || JSON.parse(userdata.data).author == "" ? "" :
															<div>
																<span className="f-w-700">Author : </span>
																{userdata.data && JSON.parse(userdata.data).author.map((authordata, index) => {
																	if (authordata.literal !== "") {
																		if (JSON.parse(userdata.data).author.length - 1 === index) {
																			return (
																				<span>{authordata.literal}</span>
																			);
																		}
																		else {
																			return (
																				<span>{authordata.literal},</span>
																			);
																		}
																	}
																	else {
																		if (JSON.parse(userdata.data).author.length - 1 === index) {
																			return (
																				<span>{authordata.given}</span>
																			);
																		}
																		else {
																			return (
																				<span>{authordata.given},</span>
																			);
																		}
																	}
																})}
															</div>}
													</Typography>
													<Typography variant="subtitle1" color="textSecondary">
														{userdata.data && JSON.parse(userdata.data) == null || userdata.data == '' || JSON.parse(userdata.data).editor == undefined || JSON.parse(userdata.data).editor == "" || JSON.parse(userdata.data).editor === null || JSON.parse(userdata.data).editor === "null" ? "" :
															<div>
																<span className="f-w-700">Editor : </span>
																{userdata.data && JSON.parse(userdata.data).editor.map((editordata, index) => {
																	if (editordata.literal !== "") {
																		if (JSON.parse(userdata.data).editor.length - 1 === index) {
																			return (
																				<span>{editordata.literal}</span>
																			);
																		}
																		else {
																			return (
																				<span>{editordata.literal},</span>
																			);
																		}
																	}
																	else {
																		if (JSON.parse(userdata.data).editor.length - 1 === index) {
																			return (
																				<span>{editordata.given}</span>
																			);
																		}
																		else {
																			return (
																				<span>{editordata.given},</span>
																			);
																		}
																	}
																})}
															</div>}
													</Typography>
													<Typography variant="subtitle1" color="textSecondary">
														{userdata.data && JSON.parse(userdata.data) == null || userdata.data == '' || JSON.parse(userdata.data).translator == undefined || JSON.parse(userdata.data).translator == "" || JSON.parse(userdata.data).translator === null || JSON.parse(userdata.data).translator === "null" ? "" :
															<div>
																<span className="f-w-700">Translator : </span>
																{userdata.data && JSON.parse(userdata.data).translator.map((translatordata, index) => {
																	if (translatordata.literal !== "") {
																		if (JSON.parse(userdata.data).translator.length - 1 === index) {
																			return (
																				<span>{translatordata.literal}</span>
																			);
																		}
																		else {
																			return (
																				<span>{translatordata.literal},</span>
																			);
																		}
																	}
																	else {
																		if (JSON.parse(userdata.data).translator.length - 1 === index) {
																			return (
																				<span>{translatordata.given}</span>
																			);
																		}
																		else {
																			return (
																				<span>{translatordata.given},</span>
																			);
																		}
																	}
																})}
															</div>}
													</Typography>
													<Typography variant="subtitle1" color="textSecondary">
														{
															userdata.data && JSON.parse(userdata.data)['publisher-place'] == undefined ? "" : <Typography variant="subtitle1" color="textSecondary">
																<span className="f-w-700">Publisher-Place : </span> {userdata.data && JSON.parse(userdata.data)['publisher-place'] == undefined ? "" : JSON.parse(userdata.data)['publisher-place']}
															</Typography>
														}
													</Typography>
												</CardContent>
											</div>
											<CardMedia
												className={classes.cover}
												image={Easy}
												title="Live from space album cover"
											/>
										</Card>
								) : (
									<div></div>
								)
							} else {
								return (
									getReferencedata(userdata, index));
							}
						})
						}
						{/* <div className="list_show f-end mt-0">
          {paginate_data > 10 ?
            <Pagination
              count={Math.ceil(paginate_data / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
              defaultPage={1}
              showFirstButton
              showLastButton
              color="primary"
              size="large"
              shape="rounded"
              autoResetPage="true"
            />
            : ""}
        </div> */}
					</div>
				</InfiniteScroll>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	const { Reference } = state;
	return { ...Reference };
};
export default connect(mapStateToProps, null)(Reference);