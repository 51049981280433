import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { Redirect } from "react-router-dom";
import Contributor from '../src/components/Contributor/index';
import Reference from './components/Book/Reference';
// import Book from './components/Book/Book';
import login from './components/LoginForm/login';
import Users from './components/Contributor/Users';

const token = localStorage.getItem('token');
if(token == "" || token == "null"){
  <Redirect to ="/login"/>
}
export const protectedroutes = [
  { path: "/login", component: login },
  { path: "/contributor", component: Contributor },
  { path: "/reference", component: Reference },
  // { path: "/book", component: Book },
  { path: "/users", component: Users },
];
