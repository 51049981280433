import React, {Component,useState} from 'react';
import { loginUserAction,setLoading } from "../../actions/index";
import "./login.css";
import { connect } from 'react-redux';
import Logo from "../../img/easy.png";
import {Container,Grid, TextField,Button, InputAdornment} from '@material-ui/core';
import {AccountCircle,LockRounded} from '@material-ui/icons';
import { Link, BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import FullPageLoader from '../FullPageLoader/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Reference from "../Book/Reference";
import {USER_LOGIN_ERROR} from '../../actions/constants';
// import disableBrowserBackButton from 'disable-browser-back-navigation';
// disableBrowserBackButton();

class login extends Component {
    constructor(props){
        super(props);
        this.state = {
            email : "",
            errors: {},
            password : "",
           
        }
    }
    onHandleLogin = (event) => {
        event.preventDefault();
        if (this.validateForm()) {
            const data = {
                email: this.state.email,
                password: this.state.password
            };
        this.props.dispatch(setLoading(true))
        this.props.dispatch(loginUserAction(data));
        // this.setState({
        //     email: '',
        //     password:''
        //   });
        }
    };
    componentDidMount()
    {
        this.props.dispatch(setLoading(false)) 
    }
    validateForm(){
        let errors = {};
        let formIsValid = true;
        if (!this.state.email) {
        formIsValid = false;
        this.props.dispatch({type: USER_LOGIN_ERROR,error:false});
        errors["email"] = "*Please enter your email.";
        }
        if (typeof this.state.email !== "undefined") {
            var pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (!pattern.test(this.state.email)) {
                formIsValid = false;
                errors["email"] = "*Please enter valid email.";
            }
        }
        if (!this.state.password) {
            formIsValid = false;
            errors["password"] = "*Please enter your password.";
            }
            this.props.dispatch({type: USER_LOGIN_ERROR,error:false});
            this.setState({errors: errors});
            return formIsValid;
        }
        
        render () {
            return (
                <div class="container">
                    {this.props.login.isLoading ? <FullPageLoader /> : ""}
                    {this.props.login.user ?  toast.success("logged in successfully ",{position: "top-right",autoClose: 10000}) : "" }
                    {this.props.login.user ? <Redirect to='/reference'  /> : "" }
                    <div className="login_form d-flex">
                        <div class="login-form-left">
                        <Container fixed >
                            <form onSubmit={this.onHandleLogin}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} alignItems="center"
                                        justify="space-between"
                                        direction="column"
                                        style={{padding:10, display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <div className="login-form-content">
                                            <Grid container justify="center">
                                            <div className="login100-form-avatar">
                                                <h1 className="logo m-0">Easy Referencing</h1>
                                            </div>
                                            </Grid>
                                            <TextField label="Email" 
                                                        margin="normal"
                                                        name="email" 
                                                        InputProps={{startAdornment:(<InputAdornment position="start"><AccountCircle/></InputAdornment>),}}
                                                        onChange={(event)=>this.setState({email:event.target.value})}
                                                        value={this.state.email}
                                                        helperText={this.state.errors.email} 
                                            >
                                            </TextField>
                                            <TextField type="password" 
                                                    label="Password" 
                                                    margin="normal" 
                                                    name="password"
                                                    InputProps={{startAdornment:(<InputAdornment position="start"><LockRounded/></InputAdornment>),}}
                                                    onChange={(event)=>this.setState({password:event.target.value})}
                                                    value={this.state.password}
                                                    helperText={this.state.errors.password} 
                                            >  
                                            </TextField>
                                            <span className="login100-form-title p-b-45 " >
                                                {this.props.login.response ? 
                                                <div className="color_reds">Please try again with valid credentials</div> 
                                                : this.state.email == "" && this.state.password == "" ?
                                                <div className="color_reds" style={{display:"none"}}>Please try again with valid credentials</div>
                                                : <div className="color_reds" style={{display:"none"}}></div> }
                                            </span>
                                            <ToastContainer/>

                                            <div className="action-form">
                                                <Button color="primary" className="primary action-form" variant="contained" type="submit" name="submit" >
                                                Log In
                                                </Button>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>
                        </div>
                        <div class="login-form-logo-right">
                            <img src={Logo}  alt="logo"></img>
                            <h1>Easy Referencing</h1>
                        </div>
                    </div>
                </div>);
            }
}

const mapStateToProps = (state) => {
    const { login } = state;
    return { login };
};
export default connect(mapStateToProps, null)(login);
