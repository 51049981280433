import { GET_STATS,GET_LIST_BOOK,ISBN_COUNT,UNAPPROVED_COUNT,APPROVED_COUNT,SET_STATS_TOTAL,BOOK_REF,SEARCH_UNAPPROVED_SUCCESS, SEARCH_SUCCESS,SEARCH_ISAPPROVED_SUCCESS,START_NUMBER,SET_STATS,SEARCH_ISBN_SUCCESS,SEARCH_NOT_FOUND,SHOW_LODER} from '../../actions/constants';

export default (state = {searcherrormsg:null,page:1,books:[],total:[],unapproved_count:0,approved_count:0,isbn_count:0}, action) => {
    switch (action.type) {
        case GET_STATS:
            return { ...state, user: action.user };
        case SET_STATS:
            return { ...state, stats: action.stats};
            case BOOK_REF:            
            return { ...state, previous: action.previous };
        case GET_LIST_BOOK:
            return { ...state, books: action.books};
        case SET_STATS_TOTAL:
            return { ...state, total: action.total};
        case ISBN_COUNT:
            return { ...state, isbn_count: action.isbn_count};
        case APPROVED_COUNT:
            return { ...state, approved_count: action.approved_count};
        case UNAPPROVED_COUNT:
            return { ...state, unapproved_count: action.unapproved_count};
        case SHOW_LODER:
            return { ...state, isLoading: action.isLoading };
        case SEARCH_SUCCESS:
            return {...state, stats: action.stats};
        case SEARCH_ISBN_SUCCESS:
            return {...state,stats: action.stats};
        case SEARCH_ISAPPROVED_SUCCESS:
            return {...state,user:action.user};
        case SEARCH_UNAPPROVED_SUCCESS:
            return {...state,user:action.user};
        case SEARCH_NOT_FOUND:            
            return { ...state, searcherrormsg: action.searcherrormsg };
        case START_NUMBER:
            return { ...state,page:action.page};
        case START_NUMBER:
            return { ...state,page:action.page};
        default:
            return state;
    }
}