import React, {Component,Fragment} from 'react';
import { connect } from 'react-redux';
import { ContributorUserAction } from "../../actions/index";
import {Container,Grid, TextField,Button,Paper } from '@material-ui/core';
import Sidebar from '../Sidebar/Sidebar';
import '../Contributor/contributor.css';
import FullPageLoader from '../FullPageLoader/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Contributor extends Component {
    constructor(props){
        super(props);
        this.state = {
            name : "",
            email : "",
            errors: {},
            password : "",
            is_approved : "",
            show : true,
            setShow : true
          
        }
    }

    handleSubmit = (event) => {
      event.preventDefault();
      if (this.validateForm()) {
          const data = {
              name : this.state.name,
              email: this.state.email,
              password: this.state.password,
              is_approved : this.state.is_approved
          };
      this.props.dispatch(ContributorUserAction(data));
      this.setState({name: '', email: '', password: '',is_approved:''});
      }
    };

    handleMsgSubmit = () =>{
      toast.success("data added successfully",{
        position: "top-right",
        //toast will be closed after 10 seconds
        autoClose: 10000
    });
     
    }
    validateForm() {
        let errors = {};
        let isValid = true;
     
        if (!this.state.name) {
          isValid = false;
          errors["name"] = "Please enter your username.";
        }
    
        if (typeof this.state.name !== "undefined") {
          const re = /^\S*$/;
          if(this.state.name.length < 6 || !re.test(this.state.name)){
              isValid = false;
              errors["name"] = "Please enter valid username.";
          }
        }
    
        if (!this.state.email) {
          isValid = false;
          errors["email"] = "Please enter your email Address.";
        }
    
        if (typeof this.state.email !== "undefined") {
            
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(this.state.email)) {
            isValid = false;
            errors["email"] = "Please enter valid email address.";
          }
        }
    
        if (!this.state.password) {
          isValid = false;
          errors["password"] = "Please enter your password.";
        }
    
        if (typeof this.state.password !== "undefined") {
          if(this.state.password.length < 6){
              isValid = false;
              errors["password"] = "Please add at least 6 charachter.";
          }
        }
    
        this.setState({errors: errors});
    
        return isValid;
    }

      
  render () {
    return (
        <div>
        <Container fixed justifycontent="space-between"
                  direction="column"  alignitems="center">
        <div  className="contributor-form">
          <form onSubmit={this.handleSubmit} >
          <div className="panel-heading">
                <h1 className="panel-title"><strong>Contributor Form</strong></h1>
            </div>
                    <div style={{display:"flex",flexDirection:"column"}}>
                        <div>
                            <TextField
                                id="standard-full-width"
                                label="Name"
                                name="name" 
                                style={{ margin: 8 }}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(event)=>this.setState({name:event.target.value})}
                                value={this.state.name}
                                
                            />
                            <div className="text-danger">{this.state.errors.name}</div>
                        </div>
                        
                        
                        <div>
                            <TextField
                                id="standard-full-width"
                                label="Email"
                                name="email" 
                                style={{ margin: 8 }}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(event)=>this.setState({email:event.target.value})}
                                value={this.state.email}
                                
                            />
                            <div className="text-danger">{this.state.errors.email}</div>
                        </div>
                
                        <div>
                            <TextField
                                id="standard-full-width"
                                label="Password"
                                type="password"
                                name="password" 
                                style={{ margin: 8 }}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(event)=>this.setState({password:event.target.value})}
                                value={this.state.password}
                            />
                            <div className="text-danger">{this.state.errors.password}</div>
                        </div>
                        <div class="form-group">
                          <label class="col-md-4 control-label" for="submit"></label>
                          <div class="col-md-8">
                          {/* <Button color="primary" variant="contained" type="submit" name="submit" onClick={() => this.handleMsgSubmit()}>Save</Button>
                          <Button variant="contained" type="reset" name="reset">Reset</Button> */}
                          </div>
                        </div>
                </div>
            </form>
            </div >
            </Container>
            <ToastContainer />
            </div>
            
    );
  }
}

const mapStateToProps = (state) => {
    const { Sidebar ,Contributor } = state;
    return { ...Sidebar , ...Contributor};
};

export default connect(mapStateToProps, null)(Contributor);
